import React, { useRef} from 'react'
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

export const Table = ({col, data, size, class_name, pagination, filterChange, filterChangeApply, 
                       pageChange, pageChangeApply, pageSizeChange, totalPages, pageNumber, pSize, tableLoadingCond})=>{

  let reactTable = useRef(null);
  
    
  if(data.length)
  {
    return (
              <ReactTable className={`col-lg-12 ${class_name}`}
              columns={col}
              data={data}
              defaultPageSize={size}
              showPagination={pagination}
              pageSizeOptions= {[50, 100, 150]}
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined && row[id] !== null
                    ? String(!(/^-?\d+$/.test(row[id])) ? row[id].toString().toLowerCase() : row[id]).includes(
                    filter.value.toLowerCase()) : false;
                }}            
              ref={(r) => (reactTable = r)}
              onFilteredChange = {()=>{return  (filterChangeApply)? filterChange(reactTable.getResolvedState().sortedData): ''}}
              onSortedChange={() => {return  (filterChangeApply)? filterChange(reactTable.getResolvedState().sortedData): ''}}
              // onPageChange={pageIndex => {return  (pageChangeApply)? pageChange(pageIndex): ''}}
              // onPageSizeChange={pageSize => {return  (pageChangeApply)? pageSizeChange(pageSize): ''}}
              // pages={(pageChangeApply)? totalPages: ''}
              // page={(pageChangeApply)? pageNumber: ''}
              // pageSize={(pageChangeApply)? pSize: ''}
              // manual={(pageChangeApply)? true : false}
              // loading={(pageChangeApply)? tableLoadingCond: ''}
              >
          </ReactTable>    
      )
  }else{ return('');}
}
