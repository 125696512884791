import {_utility_} from  '../common/index.js';

var _permission_ = {

    validate_adminland_menu(data)
    {
        let asset_arr = [], sensor_arr = [], user_arr = [], org_arr = [];
        if(data.length!==0)
        {
            data[0]["sub_menu_items"].forEach(ele => {
                
                if(localStorage.getItem('u_login_type') === "SENSE_ADMIN" && ele.replace(/ /g,'') === "Department Management".replace(/ /g,''))
                user_arr.push(ele);
                else if(ele.replace(/ /g,'')!== "Department Management".replace(/ /g,'')) 
                user_arr.push(ele);
            });

            data[1]["sub_menu_items"].forEach(ele => {

                if(localStorage.getItem('u_login_type') === "SENSE_ADMIN" && (ele.replace(/ /g,'') === "Organisation Hierarchy".replace(/ /g,'')))
                org_arr.push(ele);
                else if(ele.replace(/ /g,'')!== "Organisation Hierarchy".replace(/ /g,'')) 
                org_arr.push(ele);
            });

            data[2]["sub_menu_items"].forEach(ele => {

                if((localStorage.getItem('u_login_type') === "SENSE_ADMIN" || localStorage.getItem('u_login_type') === "ADMIN") && (ele.replace(/ /g,'') === "Asset Type".replace(/ /g,'') || ele.replace(/ /g,'') === "Meter Password".replace(/ /g,'')))
                   asset_arr.push(ele);
                else if(ele.replace(/ /g,'')!== "Asset Type".replace(/ /g,'') && ele.replace(/ /g,'')!== "Meter Password".replace(/ /g,'')) 
                asset_arr.push(ele);
            });

            data[3]["sub_menu_items"].forEach(el => {

                if(localStorage.getItem('u_login_type') === "SENSE_ADMIN" && el.replace(/ /g,'') === "Sensor Type Management".replace(/ /g,''))
                sensor_arr.push(el);
                else if(el.replace(/ /g,'')!== "Sensor Type Management".replace(/ /g,''))
                sensor_arr.push(el);  
            });
        }
        data[0]["sub_menu_items"] = user_arr;
        data[1]["sub_menu_items"] = org_arr;
        data[2]["sub_menu_items"] = asset_arr;
        data[3]["sub_menu_items"] = sensor_arr;
        return data;
    },

    sense_admin()
    {
        if(localStorage.getItem('u_login_type') === "SENSE_ADMIN")
         return true;
         else
         return false;
    },

    admin()
    {
        if(localStorage.getItem('u_login_type') === "ADMIN")
         return true;
         else
         return false;
    },

    cmd_child_menu()
    {
        return [{name: 'Command Type',url: '/CommandType', icon: 'icon-puzzle'},
            {name: 'Command Send',url: '/Command',icon: 'icon-puzzle'},
            {name: 'Command Schedule',url: '/CommandSchedule',icon: 'icon-puzzle'}];
    },

    alert_child_menu()
    {
        return [
            {name: 'Alerts',url: '/ALERT/Alerts', icon: 'icon-bell'},
            {name: 'Criteria',url: '/ALERT/Criteria',icon: 'icon-bell'},
            // {name: 'Alarms',url: '/ALERT/Alarms', icon: 'icon-bell'},
            {name: 'Notifications',url: '/ALERT/Notifications',icon: 'icon-bell'},
        ];
            
    },
    
}

export {_permission_}