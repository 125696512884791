
var ErrorMessages = {
    
    http_err_codes:{
            "200":"Success",
            "401":"Unauthorized",
            "403":"Forbidden",
            "404":"Not found"
        },
    module_error_codes : {
         "FileUploader":{"1":"File upload not Allowed !",
                        },
         "FileViewer":  { "1":"Meter Files loaded successfully!",
                          "2":"Meter Files Not Found!",
                        },
        "MeterFileView":{ "1":"Meter Files loaded successfully!",
                          "2":"Meter Files Not Found!",
                        },               
         "FileParsed":  { "1":"Files Parsed loaded successfully!",
                           "2":"Files Parsed Not Found!",
                        },
         "FileSearch":  { "1":"Files Search successfully!",
                        },  
         "Department":  { "1":"Create Department Successfully!",
                          "2":"Update Department Successfully!",
                          "3":"Department Loaded Successfully!",
                          "4":"Department List not Available!",
                          "5":"Delete Department Successfully!",
                        },
         "Role":        { "1":"Create Role Successfully!",
                          "2":"Delete Role Successfully!",
                        }, 
         "User":        { 
                          "1":"User Create Successfully!",
                          "2":"User Update Successfully!",
                          "3":"Users loaded successfully!",
                          "4":"Users not Availables!",
                          "5":"User Disable Successfully!",
                        },
         "Organisation":{ "1":"Create Organisation Successfully!",
                          "2":"Delete Organisation Successfully!",
                          "3":"Update Organisation Successfully!",
                          "4":"Migrate Organisation Successfully!",                        
                        }, 
         "Asset":       { "1":"Add Asset Successfully!",
                          "2":"Update Asset Successfully!",
                          "3":"Migrate Asset Successfully!",
                          "4":"Delete Asset Successfully!",
                        },
        "AssetType":   { "1":"AssetType Details Loaded Successfully!",
                         "2":"AssetTypes Details List Not Available!",
                         "3":"Successfully Add AssetType!",
                         "4":"Successfully Edit AssetType!",
                        }, 
         "Group":       { "1":"Group Add Successfully!",
                          "2":"Group Modify Successfully!",
                        },
         "Hub":         { "1":"Hubs Loaded Successfully!",
                          "2":"Hub List Not Available!",
                          "3":"Hub Register Successfully!",
                          "4":"Hub Configration Loaded Successfully!",
                          "5":"Hub Configration Updated Successfully!",
                          "6":"Default Configration Loaded Successfully!",
                          "7":"Default Configration Updated Successfully!",
                          "8":"Hub Delete Successfully!", 
                          "9":"Hub Updated Successfully!",                        
                        },  
        "CommandType":  { "1":"CommandType Details Loaded Successfully!",
                          "2":"Create CommandType Successfully!",
                          "3":"Command Type Delete Successfully!",
                          "4":"Command Type Edit Successfully!",
                        }, 
        "Command":      { "1":"Command Details Loaded Successfully!",
                          "2":"Send Command Successfully!",
                          "3":"Command Details List Not Available!",
                        },
        "CommandSchedule":{ "1":"CommandSchedule Details Loaded Successfully!",
                            "2":"Create CommandSchedule Successfully!", 
                            "3":"CommandSchedule Deactivated Successfully!",         
                         },
        "SensorType":    { "1":"SensorType Details Loaded Successfully!",
                           "2":"SensorTypes Details List Not Available!",
                           "3":"Create SensorType Successfully!",
                           "4":"SensorType Edit Successfully!",
                         }, 
                         
        "Sensor":       { "1":"Sensors Loaded Successfully!",
                           "2":"Sensors Not Available!",
                           "3":"Create Sensor Successfully!",
                           "4":"Sensor Set With Asset Successfully!",
                           "5": "Deactivate Sensor Successfully!",
                         },
        "meterData":     { "1": "Raw Meter Data Loaded Successfully!",
                           "2":"Raw Meter Data Not Available!",
                           "3": "Latest Meter Data Loaded Successfully!",
                           "4":" Latest Meter Data Not Available!",
                         },
        "DTProperties":  { "1": "DT Properties Loaded Successfully!",
                           "2":" DT Properties Not Available!",
                           "3": "Create DT Successfully!",
                           "4": "Edit DT Successfully!",
                         },
        "DTSapCodeProperties":  { "1": "DTSapCode Properties Loaded Successfully!",
                                  "2":" DTSapCode Properties Not Available!",
                                  "3": "Create DTSapCode Successfully!",
                                  "4": "Edit DTSapCode Successfully!",
                         },
        "MeterProperties":  { "1": "Meter Properties Loaded Successfully!",
                              "2":"Meter Properties Not Available!",
                              "3": "Create Meter Successfully!",
                              "4": "Edit meter Successfully!",
                         },
        "DTMeterMapping":{    "1": "DT Meter Mapping Loaded Successfully!",
                              "2":"DT Meter Mapping Not Available!",
                              "3": "Create DT Meter Mapping Successfully!",
                              "4": "InActive DT Meter Mapping Successfully!",
                              "5": "Delete DT Meter Mapping Successfully!",
                        },
        "AuditTrail":    {    "1": "AuditTrail Loaded Successfully!",
                             "2":"AuditTrail Not Available!",
                       } ,

        "HealthReport":    {    "1": "Health Report Loaded Successfully!",
                                "2":"Health Report Not Available!",
                                "3": "Create Health Report Successfully!",
                          } ,
        "EnergyReport":   {    "1": "Energy Report Loaded Successfully!",
                               "2":"Energy Report Not Available!",
                               "3": "Create Energy Report Successfully!",
                          } ,
        "HubHealth":     {    "1": "Hub Health Loaded Successfully!",
                                "2":"Hub Health Not Available!",
                                "3" : "Sensor Health Loaded Successfully!",
                                "4" : "Sensor Health Not Available!",
                          } ,
        "HubPing":     {    "1": "Hub Ping Loaded Successfully!",
                                "2":"Hub Ping Not Available!",
                                "3" : "Sensor Ping Loaded Successfully!",
                                "4" : "Sensor Ping Not Available!",
                          } ,
       "Alerts":          {    "1": "Alerts Loaded Successfully!",
                                "2":"Alerts Not Available!",
                                "3": "Alerts History Loaded Successfully!",
                                "4":"Alerts History Not Available!",
                          } ,
      "Notifications":    {    "1": "Notifications Loaded Successfully!",
                               "2":"Notifications Not Available!",
                           } ,
       "Criteria":          {    "1": "Criteria Loaded Successfully!",
                                 "2":"Criteria Not Available!",
                                 "3":"Create Criteria Successfully!",
                                 "4":"Update Criteria Successfully!",
                                 "5":"Delete Criteria Successfully!",
                                 "6":"Subscribe Criteria Successfully!",
                                 "7":"Unsubscribe Criteria Successfully!",
                          } ,
           "OBISCode":    {      "1":"OBIS Code Mapping Loaded Successfully!",
                                 "2":"OBIS Code Mapping Not Available!", 
                                 "3":"Update OBIS Code Mapping Successfully!",
                                 "4":"Delete OBIS Code Mapping Successfully!",
                                 "5":"Add OBIS Code Mapping Successfully!",
                          } ,
          "ConfigrationApp": {"1":"App Configration Loaded Successfully!",
                               "2":"App Configration Set Successfully!", 
                              } ,
      "ConfigrationNetwork": {  "1":"Network Configration Loaded Successfully!",
                                "2":"Network Configration Set Successfully!", 
                             } ,
            "ConfigGateway": {  "1":"Gateways Configration Loaded Successfully!",
                                 "2":"Gateways Configration Not Available!", 
                                 "3":"Set Gateway App Configration Successfully!",
                                 "4":"Gateway App Configration Loaded Successfully!",
                              } ,
            "RFGateway":  {  "1":"Gateways Loaded Successfully!",
                              "2":"Gateways Not Available!", 
                              "3":"Set Gateway App Configration Successfully!",
                              "4":"Gateway App Configration Loaded Successfully!",
                           } ,
            "ConfigNode": {  "1":"Nodes Configration Loaded Successfully!",
                             "2":"Nodes Configration Not Available!", 
                             "3":"Set Nodes App Configration Successfully!",
                             "4":"Get Nodes App Configration Successfully!",
                             "5":"Nodes App Configration Not Available!",
                              } ,
            "RFNode":         {     "1":"Nodes Loaded Successfully!",
                                 "2":"Nodes Not Available!", 
                                 "3":"Set Nodes App Configration Successfully!",
                              } ,

            "RFResult":       {     "1":"Result Loaded Successfully!",
                                 "2":"Result Not Available!", 
                              } ,
              "RFCommand":    { "1":"Command Loaded Successfully!",
                                "2":"Command List Not Available!",
                                "3":"Send Command Successfully!",
                                "4":"Cancel Command Successfully!",
                                "5":"NODE Restart Successfully!",
                                "6":"HUB Restart Successfully!",
                                "7":"Connect/Disconnect Successfully!",
                                "8":"Successfully Execute Get Billing Date Command!",
                                "9":"Successfully Execute Get Connect State Command!",
                                "10":"Successfully Execute Get Integration Period Time Command!",
                                "11":"Successfully Execute Get LockOut Period Command!",
                                "12":"Successfully Execute Get Metering Mode Command!",
                                "13":"Successfully Execute Get Profile Log Interval Command!",
                                "14":"Successfully Execute Set Metering Mode Command!",
                                "15":"Successfully Execute Set Billing Date Command!",
                                "16":"Successfully Execute Meter Time Sync Command!",
                                "17":"Successfully Execute Set Integration period Time Command!",
                                "18":"Successfully Execute Set Load Curtail Command!",
                                "19":"Successfully Execute Set Profile Log Interval Command!",
                                "20":"Successfully Execute Set Lockout Period Command!",
                                "21":"Successfully Execute Change Meter Password Command!",
                                "22":"Successfully Execute Change Meter Key Command!",
                                "23":"Successfully Execute Set Prepaid Mode Command!",
                                "24":"Successfully Execute Get Prepaid Details Command!",
                                "25":"Successfully Execute Get Load Curtail Limit Command!",
                                "26":"Successfully Execute Set Prepaid Details Command!",
                                "27":"Successfully Execute MD Reset Command!",
                              },                  
             "Dashboard": {  "1":"Profile Data Loaded Successfully!",
                             "2":"Profile Data Not Available!",
                             "3":"Open Alerts Loaded Successfully!",
                             "4":"Open Alerts Not Available!", 
                          } ,
             "MeterPassword": {   "1":"Meter Password Loaded Successfully!",
                                  "2":"Meter Password Not Available!",
                                  "3":"Meter Password Added Successfully!",
                                  "4":"Meter Password Edit Successfully!", 
                                  "5":"Meter Password Delete Successfully!",
                           } , 
            "Gateways":     {  "1":"Gateways Loaded Successfully!",
                               "2":"Gateways Not Available!", 
                               "3":"Otap Successfully!",
                            } ,
                            
            "assetHelth":     {  "1":"ParamCode List Loaded Successfully!",
                                  "2":"ParamCode List Not Available!", 
                            } , 

          "AMRMultiplier":     {  "1":"AMR Multiplier List Loaded Successfully!",
                                  "2":"AMR Multiplier List Not Available!", 
                                  "3":"AMR Multiplier Added Successfully!", 
                                  "4":"AMR MultiplierEdit Successfully!", 
                                  "5":"AMR Multiplier Delete Successfully!",
                               } ,

            "sensorHealth":     { "1": "Latest Sensor Data Loaded Successfully!",
                                  "2":"Latest Sensor Data Not Available!",
                                  "3": "Sensor Data Loaded Successfully!",
                                  "4":"Sensor Data Not Available!",
                                },
            "otap":     { "1": "Successfully Otap!",},
            "Reports":  { 
                          "1": "Billing Report Loaded Successfully!",
                          "2": "Billing Report Not Available!",
                          "3": "Monthly Energy Report Loaded Successfully!",
                          "4": "Monthly Energy Report Not Available!",
                        },
                        
          "MeterCtPt": {   "1":"Meter CtPt List Loaded Successfully!",
                                  "2":"Meter CtPt List Not Available!",
                                  "3":"Meter CtPt Added Successfully!",
                                  "4":"Meter CtPt Edit Successfully!", 
                                  "5":"Meter CtPt Delete Successfully!",
                           } , 
    },
    composeErrorMessage(statusCode, moduleName, errorCode, showHTTPStatus)
    {
        var base_messsage = "";
        if(showHTTPStatus){
            
            if(statusCode.toString() in this.http_err_codes)
                base_messsage = this.http_err_codes[statusCode.toString()];
            if(moduleName in this.module_error_codes)
                if(errorCode.toString() in this.module_error_codes[moduleName])
                    base_messsage += this.module_error_codes[moduleName][errorCode.toString()] ;
        }
        else{
            if(moduleName in this.module_error_codes)
                if(errorCode.toString() in this.module_error_codes[moduleName])
                    base_messsage += this.module_error_codes[moduleName][errorCode.toString()] ;
        }
        return base_messsage;

    },

    composeCommonErrorMsg(statusCode, error){
      var showMessage="";
      if(statusCode === 400){
        showMessage = "Bad Request!";
      }else if(statusCode === 401){
         showMessage = "You are Unauthorized Access This!";
          window.localStorage.setItem('u_name', "");
          window.localStorage.setItem('acc_token',"");
          window.localStorage.setItem('u_login_type', "");
          window.location.replace("hes/login");
      }else if(statusCode === 402){
         showMessage = "Payment Required!";
       }else if(statusCode === 403){
         showMessage = "You are Forbidden Access This!";
       }else if(statusCode === 404){
        showMessage = "Not Found!";
      }else if(statusCode === 405){
        showMessage = "Method Not Allowed!";
      }else if(statusCode === 406){
        showMessage = "Not Acceptable!";
      }else if(statusCode === 407){
        showMessage = "Proxy Authentication Required!";
      }else if(statusCode === 408){
        showMessage = "Request Timeout!";
      }else if(statusCode === 409){
        showMessage = "Conflict!";
      }else if(statusCode === 500){
         if(error.response.data)
           showMessage = error.response.data;
           else
            showMessage = "Internal Server Error!";
      }else if(statusCode === 501){
        showMessage = "Not Implemented!";
      }else if(statusCode === 502){
        showMessage = "Bad Gateway!";
      }else if(statusCode === 503){
        showMessage = "Service Unavailable!";
      }else if(statusCode === 504){
        showMessage = "Gateway Timeout!";
      }else{
        showMessage = error.response.data;
       }
       return showMessage;
    },

    composeCommonValidationMsg(){
      return 'Please Fill All Mandatory Fields!';  
    }

    
}

export {ErrorMessages}