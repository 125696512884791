
var _asset_ = {

    mandatory_property: [
      {"name":"code", "value":"Code"},
      {"name":"name", "value":"Name"},
      {"name":"assetMake", "value":"Make"},
      {"name":"assetModel", "value":"Model"},
      {"name":"latitude", "value":"Latitude"},
      {"name":"longitude", "value":"Longitude"}
    ],
    makeArr:["Genus", "HPL", "Inesh", "ISKRAEMECO", "LINK", "LNT", "LNG", "Secure", "Flash", "Zen"],
    modelArr:["DLMS"],
}
export {_asset_}