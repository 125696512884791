import React, { Component } from "react";
import {Switch, Route, BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import './App.scss';
import {URL_PART} from './common/urls.js'
import ReactNotification from 'react-notifications-component'
import {_utility_} from './common/Utility.js';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
//const Login = React.lazy(() => import('./views/Pages/Login'));
 const Login =  _utility_.login;
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


export default class App extends Component {
  constructor() {
    super();

    var token_str =  window.localStorage.getItem('acc_token');
    
    if(!token_str || token_str === "")
      token_str = null;

    this.state = {
      loggedinstatus: "NOT_LOGGED_IN",
      user: token_str,
      user_details:{},
      token: token_str
    };

    if(token_str)
    this.state.loggedinstatus = "LOGGED_IN";
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.getUserProperties = this.getUserProperties.bind(this);
  }

  loading() {
    let _style ={
      width: '0.7rem',
      height: '0.7rem'
    }
    return(
        <div className="text-center"> 
        <div className="spinner-grow text-muted" style={_style}></div>
        <div className="spinner-grow text-muted" style={_style}></div>
        <div className="spinner-grow text-muted" style={_style}></div>
      </div>
    );
  }

    checkLoginStatus() {

      if(this.state.token === null)
      {
        // console.log("Not Logged in");
        this.handleLogout();
      }
      else
      {
        var auth = "Bearer " + this.state.token;
        axios({
        url: URL_PART.CHECK_LOGIN_URL,
        method: 'get',
        headers: {
            "Accept": "*/*",
            "Authorization": auth
            }
          }).then(response => {
          
          if(response.status === 200){            
            this.getUserProperties(response.data.token);
          }
        })
        .catch(error => {
          this.handleLogout();
          window.location.reload();
        });
        
      }

  }
  getUserProperties(token)
  {
    var getOrg = URL_PART.USER_GET_MY_PROPS;
    var auth = "Bearer " + token;
    axios
        ({
        url: getOrg,
        method: 'get',
        headers: {
            "Accept": "*/*",
            "Authorization": auth
            }
        })
        .then(response => {
          if(response.status === 200){
            this.state.user_details = response.data;
            window.localStorage.setItem('acc_token',token);
            this.setState({loggedinstatus: "LOGGED_IN", user: token });
          }
        })
        .catch(error => {
          console.log("Failed to load user properties", error);
        });
  }

  componentWillMount() {
    this.checkLoginStatus();
  }

  handleLogout() {
    this.setState({
      loggedinstatus: "NOT_LOGGED_IN",
      user: {}
    });
    // localStorage.clear();
    window.localStorage.setItem('u_name', "");
    window.localStorage.setItem('acc_token',"");
    window.localStorage.setItem('u_login_type', "");
  }

  handleLogin(data, detail) {
    this.setState({
      loggedinstatus: "LOGGED_IN",
      user: data.user,
      user_details: detail
    });
  }

  render() {
    return (
      <Router>
            <React.Suspense fallback={this.loading()}>
              <Switch>
                <Route  path="/login" name="Login Page" render={props => 
                  <Login 
                  {...props} 
                  loggedinstatus={this.state.loggedinstatus} 
                  user={this.state.user}
                  user_details={this.state.user_details}
                  handleLogin={this.handleLogin}
                  handlelogout={this.handleLogout}/>} />

                
                <Route  path="/404" name="Page 404" render={props => <Page404 {...props}/>} />

                <Route   path="/500" name="Page 500" render={props => <Page500 {...props}/>} />

                <Route   path="/" name="Home" render={props => 
                  <DefaultLayout 
                  {...props} 
                  loggedinstatus={this.state.loggedinstatus} 
                  user={this.state.user}
                  user_details={this.state.user_details}
                  handlelogout={this.handleLogout}
                  />} />
              </Switch>
            </React.Suspense>
             <ReactNotification />
            </Router>
     );
    
  }
}
