 let notificationObj = {}, latestMeterObj = {}, setObjectVal = {};

var service = {

    setPortNoFunct()
    {
         let arr = [];
        for(let i=0; i<=50; i++){
            arr.push({ value:i, label: i });
        }

        return arr;
    },

    setFrequencyFunct()
    {
         let freArr = [];
        for(let i=15; i<=1440; i++){
            freArr.push({ value:i, label: i });
        }

        return freArr;
    },

    setSlotsFunct(val)
    {
        let _slotArr = [];
        for(let i=1; i<=val; i++){
            _slotArr.push({ value:i, label: i });
        }

        return _slotArr;
    },

    _reactSelectStyle_()
    {
        let targetHeight = 28, styles = {};

        return   styles = {
            control: (base) => ({
                ...base,
                minHeight: "initial"
            }),
            valueContainer: (base) => ({
                ...base,
                height: `${targetHeight - 1 - 1}px`,
                padding: "0 8px"
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: `${(targetHeight - 20 - 1 - 1) / 2}px`
            }),
            dropdownIndicator: (base) => ({
                ...base,
                padding: `${(targetHeight - 20 - 1 - 1) / 2}px`
            })
            };
    },

    _setNotifier(obj)
    {
      notificationObj = obj;
    },
    _getNotifier()
    {
      return notificationObj;
    },
    _setLatestMeterParmObj(obj)
    {
        latestMeterObj = obj;
    },
    _getLatestMeterParmObj()
    {
      return  latestMeterObj;
    },
    _textCapitalize(ele)
    {
      return (ele.charAt(0).toUpperCase() + ele.slice(1));
    },

    _UNIQUE_KEY_(){return(Math.random().toString(30).slice(2));},
    
    setObject(obj){setObjectVal = obj;},
    getObject(){return setObjectVal;}
}

export {service}