   const API_URL = window.rootConfig._baseUrl_;


var baseUrl_user =  `${API_URL}:8083`,
	baseUrl_org =       `${API_URL}:8081`,
	baseUrl_assets =    `${API_URL}:8082`,
	baseUrl_mdm =       `${API_URL}:8080`,
	baseUrl_hub =       `${API_URL}:8087`,
	baseUrl_sensor =    `${API_URL}:8087`,
	baseUrl_command =   `${API_URL}:8086`,
	baseUrl_meterData = `${API_URL}:8089`,
	baseUrl_auditData =  `${API_URL}:8092`,
	baseUrl_healthData =  `${API_URL}:8093`,
	baseUrl_alerts =    `${API_URL}:8088`,
	baseUrl_amrCodes =  `${API_URL}:8085`,
	baseUrl_notifications =  `${API_URL}:8090`,
	baseUrl_configuration =  `${API_URL}:9999`,
	baseUrl_config = `${API_URL}:8099`,
	baseUrl_dashboard = `${API_URL}:8091`,
	baseUrl_cdf = `${API_URL}:8095`;

const URLS = 
{
	BASE_URL:"https://putsreq.com/Ox3PLI45eCEa6rHXjXXh",
	SWAGER_URL:"http://192.168.1.5:8081",
	MQTT_SERVER_URL:"54.84.164.247"
};

const URL_PART = 
{
	CHECK_LOGIN_URL:baseUrl_user+"/user/auth/refresh",
	LOGIN_URL:baseUrl_user+"/user/auth/login?loginMode=",
	LOGIN_PASSWORD_RESET:baseUrl_user+"/user/auth/changePassword",
	
	USER_GET_ALL_USER_PROPS:baseUrl_user+"/user/searchUser?name=",
	USER_GET_MY_PROPS:baseUrl_user+"/user/getMyDetails",
	USER_GET_ALL_ACCEES_LIST:baseUrl_user+"/user/getAccessRights",
	USER_GET_ALL_USERS:baseUrl_user+"/user/getAllUsers",
	USER_GET_USER_ACCESSIBLE_ORG:baseUrl_org+"/org/getUserAccessibleOrgs",
	USER_UPDATE_USER:baseUrl_user+"/user/updateUser",
	USER_GET_ALL_DEPARTMENTS : baseUrl_user+"/user/getDepartments",
	USER_GET_DEPARTMENT_BY_CODE:baseUrl_user+"/user/getDepartmentByCode",
	USER_ADD_DEPARTMET:baseUrl_user+"/user/createDepartment",
	USER_UPDATE_DEPARTMET:baseUrl_user+"/user/updateDepartment",
	USER_CREATE_ROLE:baseUrl_user+"/user/createRole",
	USER_CREATE_USER:baseUrl_user+"/user/createUser",
	USER_DELETE_DEPARTMENT:baseUrl_user+"/user/deleteDepartment",
	USER_DELETE_ROLE:baseUrl_user+"/user/deleteRole",
	USER_DISABLE:baseUrl_user+"/user/disableUser",
	

	ORG_STRUCTURE_URL:baseUrl_org+"/org/getAllSubTypes",
	ORG_CHILDREN_URL:baseUrl_org+"/org/getAccessibleOrgs",
	ORG_ASSET_URL:baseUrl_assets+"/assets/getAssets/",
	ORG_USER_ASSET_URL:baseUrl_assets+"/assets/getUserAssets/",
	ORG_ASSET_TYPE_URL:baseUrl_assets+"/assets/getAssetTypes",
	ORG_ASSET_CREATE_GROUP:baseUrl_assets+"/assets/createAssetGroup",
	ORG_ASSET_MODIFY_GROUP:baseUrl_assets+"/assets/modifyAssetGroup",
	ORG_ASSET_GET_GROUPS:baseUrl_assets+"/assets/getAssetGroups",
	ASSET_BULK_UPLOAD:baseUrl_assets+"/assets/bulk/upload",
	ASSET_BULK_GET_SAMPLE:baseUrl_assets+"/assets/bulk/getSample",
	GET_ASSET_TYPES:baseUrl_assets+"/assets/getAssetTypes",
	CREATE_ASSET_TYPE:baseUrl_assets+"/assets/createAssetType",
	EDIT_ASSET_TYPE:baseUrl_assets+"/assets/editAssetType",
	GET_ORG_HIERARCHY:baseUrl_assets+"/assets/getOrgHierarchy",
	GET_ASSET_BY_CODE:baseUrl_assets+"/assets/getAssetByCode",

	ORG_CREATE_ORG:baseUrl_org+"/org/add",
	ORG_MODIFY_ORG:baseUrl_org+"/org/modifyOrgPart",
	ORG_MIGRATE_ORG:baseUrl_org+"/org/reassign",
	ORG_DELETE_ORG:baseUrl_org+"/org/delete/",
	ORG_BULK_UPLOAD:baseUrl_org+"/org/bulk/upload",
	ORG_BULK_HIERARCHY:baseUrl_org+"/org/bulk/hierarchy",
	ORG_BULK_GET_SAMPLE:baseUrl_org+"/org/bulk/getSample",

	ORG_CREATE_ASSET: baseUrl_assets+"/assets/createAsset",
	ORG_MODIFY_ASSET: baseUrl_assets+"/assets/editAsset",
	ORG_MIGRATE_ASSET: baseUrl_assets+"/assets/reassignAsset",
	ORG_DELETE_ASSET: baseUrl_assets+"/assets/deleteAsset",

	METER_FILE_UPLOAD: baseUrl_mdm+"/mdm/meter_file/",
	PARSED_METER_FILE: baseUrl_mdm+"/mdm/parsed_meter_file/",
    METER_FILE_OF_MONTH_YEAR: baseUrl_mdm+"/mdm/meter_file_of_month_year/",
	METER_FILE_SEARCH: baseUrl_mdm+"/mdm/search_meter_file/",
	SEARCH_PARSED_METER_FILE: baseUrl_mdm+"/mdm/search_parsed_meter_file/",
	METER_FILE_BY_ORGNISATION: baseUrl_mdm+"/mdm/meter_file_by_organisation/",
	GET_DT_PROPERTIES:baseUrl_mdm+"/mdm/dt_properties/",
	GET_DT_SAP_CODE_PROPERTIES:baseUrl_mdm+"/mdm/dt_sap_eqp_code_properties/",
	GET_METER_PROPERTIES:baseUrl_mdm+"/mdm/meter_properties/",
	GET_DT_METER_MAPPING:baseUrl_mdm+"/mdm/dt_meter_mapping/",
	IN_ACTIVE_DT_METER_MAPPING:baseUrl_mdm+"/mdm/inactive_dt_meter_mapping/",
	DELETE_DT_METER_MAPPING:baseUrl_mdm+"/mdm/delete_dt_meter_mapping/",
	GET_HEALTH_REPORTS:baseUrl_mdm+"/mdm/health_report/",
	GET_ENERGY_REPORTS:baseUrl_mdm+"/mdm/energy_report/",


	GET_ORG_HEALTH:baseUrl_healthData+"/healthdata/orgHealth",
	GET_SENSOR_HEALTH:baseUrl_healthData+"/healthdata/sensorHealth",
	GET_ORG_PING:baseUrl_healthData+"/healthdata/orgPing",
	GET_SENSOR_PING:baseUrl_healthData+"/healthdata/sensorPing",
	LATEST_SENSOR_HEALTH:baseUrl_healthData+"/healthdata/latestSensorHealth",
	LATEST_SENSOR_PING:baseUrl_healthData+"/healthdata/latestSensorPing",

	GET_ALL_ALERTS:baseUrl_alerts+"/alert/getAllAlerts",
	GET_ALERT:baseUrl_alerts+"/alert/getAlert",
	GET_ALL_CRITERIA:baseUrl_alerts+"/alert/getAllCriteria",
	CREATE_ALERT_CRITERIA:baseUrl_alerts+"/alert/createAlertCriteria",
	UPDATE_ALERT_CRITERIA:baseUrl_alerts+"/alert/updateAlertCriteria",
	DELETE_ALERT_CRITERIA:baseUrl_alerts+"/alert/deleteAlertCriteria",
	ALERT_SUBSCRIBE:baseUrl_alerts+"/alert/subscribe",
	ALERT_UN_SUBSCRIBE:baseUrl_alerts+"/alert/unsubscribe",
	GET_ALL_SUBSCRIPTION:baseUrl_alerts+"/alert/getAllSubscription",
	GET_OPEN_ALERTS:baseUrl_alerts+"/alert/openAlerts",
	CRITERIA_WISE_OPEN_ALERT_COUNT:baseUrl_alerts+"/alert/criteriaWiseOpenAlertCount",
	GET_ALERTS_FOR_ASSET:baseUrl_alerts+"/alert/getAlertsForAsset",
	GET_ALL_ALARMS:baseUrl_alerts+"/alert/getAllAlarms",

	GET_CODE_MAPS:baseUrl_amrCodes+"/amrCodes/getCodeMaps",
	GET_SAMPLE:baseUrl_amrCodes+"/amrCodes/getSample",
	ADD_UPDATE:baseUrl_amrCodes+"/amrCodes/addUpdate",
	DELETE_OBISCODE:baseUrl_amrCodes+"/amrCodes/delete",
	BULK_UPLOAD_OBISCODE:baseUrl_amrCodes+"/amrCodes/bulkUpload",
	GET_METER_TYPE_LIST:baseUrl_amrCodes+"/amrCodes/meterTypeList",
	GET_METER_CATEGORY_LIST:baseUrl_amrCodes+"/amrCodes/meterCategoryList",
	GET_AMR_MULTIPLIERS:baseUrl_amrCodes+"/amrCodes/getAmrMultipliers",
	ADD_EDIT_AMR_MULTIPLIER:baseUrl_amrCodes+"/amrCodes/addEditAmrMultiplier",
	DELETE_AMR_MULTIPLIER:baseUrl_amrCodes+"/amrCodes/deleteAmrMultiplier",



	GET_ALL_HUBS: baseUrl_hub+"/hub/getAllHubs",
	REGISTER_HUBS: baseUrl_hub+"/hub/registerHub",
	GET_HUBS_CONFIG: baseUrl_hub+"/hub/getHubConfig",
	UPDATE_HUB_CONFIG:baseUrl_hub+"/hub/updateHubConfig",
	DELETE_HUB: baseUrl_hub+"/hub/deleteHub",
	UPDATE_DEFAULT_HUB_CONFIG: baseUrl_hub+"/hub/updateDefaultHubConfig",
	GET_DEFAULT_HUB_CONFIG: baseUrl_hub+"/hub/getDefaultHubConfig",
    UPDATE_HUB: baseUrl_hub+"/hub/updateHub",



	GET_ALL_COMMAND_TYPES:baseUrl_command+"/command/getAllCommandTypes",
	CREATE_NEW_COMMAND_TYPE:baseUrl_command+"/command/createCommandType",
	GET_COMMAND_BY_CODE:baseUrl_command+"/command/getCommandByCode",
	DELETE_COMMAND_TYPE:baseUrl_command+"/command/deleteCommandType",
	EDIT_COMMAND_TYPE:baseUrl_command+"/command/editCommandType",
	GET_ALL_COMMANDS:baseUrl_command+"/command/getAllCommands",
	CREATE_COMMAND:baseUrl_command+"/command/createCommand",
	GET_ALL_COMMAND_SCHEDULE:baseUrl_command+"/command/getAllCommandSchedules",
	DEACTIVATE_COMMAND_SCHEDULE:baseUrl_command+"/command/deactivateCommandSchedule",
	CREATE_COMMAND_SCHEDULE:baseUrl_command+"/command/createCommandSchedule",

	GET_SENSOR_TYPES: baseUrl_sensor+"/sensor/getSensorTypes",
	GET_SENSOR_BY_TYPE: baseUrl_sensor+"/sensor/getSensorsByType",
	CREATE_SENSOR_TYPES: baseUrl_sensor+"/sensor/createSensorType",
	EDIT_SENSOR_TYPE_COMMANDS:baseUrl_sensor+"/sensor/editSensorTypeCommands",
	EDIT_SENSOR_TYPE_PROPERTIES: baseUrl_sensor+"/sensor/editSensorTypeProperties",
	GET_SENSOR_FOR_HUB: baseUrl_sensor+"/sensor/getSensorsForHub",
	GET_SENSOR_FOR_UUID: baseUrl_sensor+"/sensor/getSensorForUuid",
	GET_SENSOR_FOR_ASSET: baseUrl_sensor+"/sensor/getSensorsForAsset",
	CREATE_SENSOR:baseUrl_sensor+"/sensor/createSensor",
	SET_SENSOR_ASSET:baseUrl_sensor+"/sensor/setSensorAsset",
	DE_ACTIVATE_SENSOR:baseUrl_sensor+"/sensor/deActivateSensor",
	UPLOAD_OTAP_FILE:baseUrl_sensor+"/sensor/uploadOtapFile",
	SENSOR_OTAP:baseUrl_sensor+"/sensor/sensorOtap",

	AMR_DATA:baseUrl_meterData+"/meterdata/amrData",
	LATEST_AMR_DATA:baseUrl_meterData+"/meterdata/latestAmrData",
	LIST_METER_PASSWORD:baseUrl_meterData+"/meterconfig/listMeterPassword",
	CREATE_METER_PASSWORD:baseUrl_meterData+"/meterconfig/createMeterPassword",
	EDIT_METER_PASSWORD:baseUrl_meterData+"/meterconfig/editMeterPassword",
	DELETE_METER_PASSWORD:baseUrl_meterData+"/meterconfig/deleteMeterPassword",
	LATEST_AMR_SENSOR_DATA:baseUrl_meterData+"/meterdata/latestAmrSensorData",
	GET_OBIS_CODES:baseUrl_meterData+"/meterconfig/getObisCodes",
	LIST_METER_CT_PT:baseUrl_meterData+"/meterconfig/listMeterCtPt",
	ADD_METER_CT_PT:baseUrl_meterData+"/meterconfig/addMeterCtPt",
	EDIT_METER_CT_PT:baseUrl_meterData+"/meterconfig/editMeterCtPt",
	DELETE_METER_CT_PT:baseUrl_meterData+"/meterconfig/deleteMeterCtPt",
	AMR_DATA_BY_METERS:baseUrl_meterData+"/meterdata/amrDataByMeters",





	AUDIT_DATA_ASSET:baseUrl_auditData+"/auditdata/asset",
	AUDIT_DATA_ORG:baseUrl_auditData+"/auditdata/org",
	AUDIT_DATA_SENSOR:baseUrl_auditData+"/auditdata/sensor",
	AUDIT_DATA_USER:baseUrl_auditData+"/auditdata/user",

	GET_ALERT_NOTIFICATIONS_URL:baseUrl_notifications+"/notification/getNotifications",
	ALERT_NOTIFICATION_SEEN_URL:baseUrl_notifications+"/notification/markNotificationSeen",

	GET_GW_APP_DEFAULT_CONFIG:baseUrl_configuration+"/config/getGwAppDefaultConfig",
	SET_GW_APP_DEFAULT_CONFIG:baseUrl_configuration+"/config/setGwAppDefaultConfig",
	GET_GW_NETWORK_DEFAULT_CONFIG:baseUrl_configuration+"/config/getGwNetworkConfig",
	SET_GW_NETWORK_DEFAULT_CONFIG:baseUrl_configuration+"/config/setGwNetworkDefaultConfig",
	GET_NODES:baseUrl_configuration+"/config/getNodes",
	SET_GW_APP_CONFIG:baseUrl_configuration+"/config/setGwAppConfig",
	GET_NODES_CONFIG:baseUrl_configuration+"/config/getNodes",
	SET_NODE_APP_CONFIG:baseUrl_configuration+"/config/setNodeAppConfig",
	UPLOAD_GW_OTAP_FILE:baseUrl_configuration+"/config/uploadGwOtapFile",
	GW_OTAP:baseUrl_configuration+"/config/gwOtap",
	RF_COMMAND:baseUrl_configuration+"/command/rfCommand",
	LIST_COMMANDS:baseUrl_configuration+"/command/listCommands",
	CANCEL_COMMANDS:baseUrl_configuration+"/command/cancelCommands",
	RESTART_NODE:baseUrl_configuration+"/command/restartNode",
	RESTART_GATEWAY:baseUrl_configuration+"/command/restartGateway",
	CONNECT_DISCONNECT:baseUrl_configuration+"/command/connectDisconnect",
	GET_BILLING_DATE:baseUrl_configuration+"/command/getBillingDate",
	GET_CONNECT_STATE:baseUrl_configuration+"/command/getConnectState",
	GET_INTEGRATION_PERIOD_TIME:baseUrl_configuration+"/command/getIntegrationPeriodTime",
	GET_LOCK_OUT_PERIOD:baseUrl_configuration+"/command/getLockOutPeriod",
	GET_METERING_MODE:baseUrl_configuration+"/command/getMeteringMode",
	GET_PROFILE_LOG_INTERVAL:baseUrl_configuration+"/command/getProfileLogInterval",
	SET_METERING_MODE:baseUrl_configuration+"/command/setMeteringMode",
	SET_BILLING_DATE:baseUrl_configuration+"/command/setBillingDate",
	METER_TIME_SYNC:baseUrl_configuration+"/command/meterTimeSync",
	SET_INTEGRATION_PERIOD_TIME:baseUrl_configuration+"/command/setIntegrationPeriodTime",
	SET_LOAD_CURTAIL:baseUrl_configuration+"/command/setLoadCurtail",
	SET_PROFILE_LOG_INTERVAL:baseUrl_configuration+"/command/setProfileLogInterval",
	SET_LOCK_OUT_PERIOD:baseUrl_configuration+"/command/setLockOutPeriod",
	CHANGE_METER_PASSWORD:baseUrl_configuration+"/command/changeMeterPassword",
	CHANGE_METER_KEY:baseUrl_configuration+"/command/changeMeterKey",
	SET_PREPAID_MODE:baseUrl_configuration+"/command/setPrepaidMode",
	GET_PREPAID_DETAILS:baseUrl_configuration+"/command/getPrepaidDetails",
	GET_LOAD_CURTAIL:baseUrl_configuration+"/command/getLoadCurtail",
	SET_PREPAID_DETAILS:baseUrl_configuration+"/command/setPrepaidDetails",
	// UPLOAD_METER_OTAP_FILE:baseUrl_configuration+"/config/uploadMeterOtapFile",
	UPLOAD_METER_OTAP_FILE:baseUrl_configuration+"/command/uploadMeterOtapFile",
	SEND_METER_OTAP_GW:baseUrl_configuration+"/command/sendMeterOtapGw",
	ACTIVATE_METER_OTAP:baseUrl_configuration+"/command/activateMeterOtap",
	MD_RESET:baseUrl_configuration+"/command/mdReset",
	SET_TARIFF_TODS:baseUrl_configuration+"/command/setTariffTods",
	GET_TARIFF_TODS:baseUrl_configuration+"/command/getTariffTods",
	GET_METER_DETAILS:baseUrl_configuration+"/command/getMeterDetails",
	PROPOGATE_FILE:baseUrl_configuration+"/command/propogateFile",




	GET_ALL_GATEWAYS:baseUrl_config+"/config/getAllGateways",
	GET_GW_APP_CONFIG:baseUrl_config+"/config/getGwAppConfig",
	GET_NODE_MAPPING:baseUrl_config+"/config/getNodeMapping",
	GET_ALL_NODE_MAPPINGS:baseUrl_config+"/config/getAllNodeMappings",
	GET_COMMAND_REQUESTS:baseUrl_config+"/command/getCommandRequests",
	GET_COMMAND_REQUEST_FOR_METER:baseUrl_config+"/command/getCommandRequestsForMeter",
	GET_GATEWAY:baseUrl_config+"/config/getGateway",
	CREATE_COMMAND_REQUEST:baseUrl_config+"/command/createCommandRequest",
	GET_COMMAND_REQUEST_FOR_COMMAND_TYPE:baseUrl_config+"/command/getCommandRequestsForCommandType",
	GET_NODE_APP_CONFIG:baseUrl_config+"/config/getNodeAppConfig",


	METER_GET_BILLING_DATE:baseUrl_config+"/meter/getBillingDate",
	METER_GET_CONNECT_STATE:baseUrl_config+"/meter/getConnectState",
	METER_GET_INTEGRATION_PERIOD_TIME:baseUrl_config+"/meter/getIntegrationPeriodTime",
	METER_GET_LIST_COMMANDS:baseUrl_config+"/meter/getListCommands",
	METER_GET_LOAD_CURTAIL_LIMIT:baseUrl_config+"/meter/getLoadCurtailLimit",
	METER_GET_LOCKOUT_PERIOD:baseUrl_config+"/meter/getLockoutPeriod",
	METER_GET_METER_TIME_SYNC:baseUrl_config+"/meter/getMeterTimeSync",
	METER_GET_METERING_MODE:baseUrl_config+"/meter/getMeteringMode",
	// METER_GET_PREPAID_DETAILS:baseUrl_config+"/meter/getPrepaidDetails",
	METER_GET_PROFILE_LOG_INTERVAL:baseUrl_config+"/meter/getProfileLogInterval",
	METER_GET_PREPAID_DETAILS:baseUrl_config+"/meter/getPrepaidDetails",
	METER_GET_TARIFF_TODS:baseUrl_config+"/meter/getTariffTods",

	



	ASSET_PROFILE_DATA_COUNT:baseUrl_dashboard+"/meterdashboard/assetProfileDataCount",
	ASSET_PROFILE_DATA_LIST:baseUrl_dashboard+"/meterdashboard/assetProfileDataList",
	PEAK_DEMAND:baseUrl_dashboard+"/meterdashboard/peakDemand",
	ENERGY_CONSUMED:baseUrl_dashboard+"/meterdashboard/energyConsumed",
	VOLTAGE_TREND:baseUrl_dashboard+"/meterdashboard/voltageTrend",
	LOAD_TREND:baseUrl_dashboard+"/meterdashboard/loadTrend",
	VOLTAGE_TREND_LIST:baseUrl_dashboard+"/meterdashboard/voltageTrendList",
	LOAD_TREND_LIST:baseUrl_dashboard+"/meterdashboard/loadTrendList",
	PF_TREND:baseUrl_dashboard+"/meterdashboard/pfTrend",
	PF_TREND_LIST:baseUrl_dashboard+"/meterdashboard/pfTrendList",
	OUTAGE_TREND:baseUrl_dashboard+"/meterdashboard/outageTrend",
	OUTAGE_TREND_LIST:baseUrl_dashboard+"/meterdashboard/outageTrendList",
	CURRENT_UNBALANCED_TREND:baseUrl_dashboard+"/meterdashboard/currentUnbalancedTrend",
	CURRENT_UNBALANCED_TREND_LIST:baseUrl_dashboard+"/meterdashboard/currentUnbalancedTrendList",
	CRITERIA_WISE_ALERT_COUNT:baseUrl_dashboard+"/meterdashboard/criteriaWiseAlertCount",
	ALERTS_BY_CRITERIA_LIST:baseUrl_dashboard+"/meterdashboard/alertsByCriteria",
	METER_REPORT_ENERGY:baseUrl_dashboard+"/meter-report/energy",
	METER_REPORT_PEAK_DEMAND:baseUrl_dashboard+"/meter-report/peakDemand",
	METER_REPORT_OUTAGE:baseUrl_dashboard+"/meter-report/outage",
	METER_REPORT_CONSOLIDATED_DEMAND:baseUrl_dashboard+"/meter-report/consolidatedDemand",
	METER_REPORT_BILLING:baseUrl_dashboard+"/meter-report/billing",
	METER_REPORT_MONTHLY_ENERGY:baseUrl_dashboard+"/meter-report/monthlyEnergy",
	METER_REPORT_SLA_BILLING:baseUrl_dashboard+"/meter-report/slaBilling",
	METER_REPORT_SLA_MID_NIGHT:baseUrl_dashboard+"/meter-report/slaMidnight",
	METER_REPORT_SLA_BILLING_DETAILS:baseUrl_dashboard+"/meter-report/slaBillingDetails",
	METER_REPORT_SLA_MID_NIGHT_DETAILS:baseUrl_dashboard+"/meter-report/slaMidnightDetails",
	METER_REPORT_SLA_LOAD:baseUrl_dashboard+"/meter-report/slaLoad",
	METER_REPORT_SLA_LOAD_DETAILS:baseUrl_dashboard+"/meter-report/slaLoadDetails",
	METER_REPORT_BLOCK_SURVEY:baseUrl_dashboard+"/meter-report/blockSurvey",
	METER_REPORT_BLOCK_SURVEY_DETAILS:baseUrl_dashboard+"/meter-report/blockSurveyDetails",
	METER_REPORT_GET_LATEST_EVENT_REPORT:baseUrl_dashboard+"/meter-report/getLatestEventReport",


	DOWNLOAD_CDF_FILE:baseUrl_cdf+"/cdf/downloadCdfFile",
};


const STRINGS = 
{
	LOGIN_SUCCESS:'OK',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGIN_FAILED_UP_MISMATCH: 'Bad credentials!',
	COMPANY_NAME:'test',
	COMPANY_TYPE:'company'
}


export {URLS, STRINGS, URL_PART, baseUrl_mdm}
