import React, { Component } from 'react'
import ReactExport from 'react-data-export';
import {Validation} from './Validation';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class ExportReactCSV extends Component {

       constructor(props)
       {
           super(props);
           this.state = {
               dataSetArr:[{columns: [], data:[],}],
               refreshCond:false,
           }

           this._state_ = {};

        //    this.props.reportStyle ={
        //        font_size: "9",
        //        font_family:"Calibri",
        //        font_weight: false,
        //        color:"ffffff",
        //        alignment:{vertical:"center", horizontal:"center"},
        //        backgroundColor:"0f243e",
        //    }
           
       }


       report_fun()
       { 
          let dataArr = [], columnArr = [], getKeyArr = [];
          this._state_.dataSetArr[0]["columns"]= [];
          this._state_.dataSetArr[0]["data"]= [];
          this.setState(this._state_ );

        try
        {
            if(this.props.csvData.length!==0)
            {
                this.props.csvData.forEach((ele, indx)=>{               
                    getKeyArr = Object.keys(ele);
                    getKeyArr.forEach(header=>{
                     if(Array.isArray(ele[header]) === false || typeof ele[header]!== 'object')
                     {
                        let check = columnArr.find(o=> o.title === header);
                        if(check === undefined)
                        {
                            if(Validation.nullAndUndefineCheckFn(header))
                            {
                              // if((this.props.csvData.filter(x=>x[header] === null).length)!== this.props.csvData.length)
                              // { 
                              //       columnArr.push(
                              //         {title: header, style: {font: {sz: "9", name: 'Calibri', bold: true, color:{rgb: "ffffff"},},
                              //         alignment:{vertical: "center",horizontal: "center"},
                              //         border:{top:{"style":"thin"},bottom:{"style":"thin"},left:{"style":"thin"},right:{"style":"thin"}},
                              //         fill: {patternType: "solid", fgColor: {rgb: "0f243e"}}}},
                              //       );  
                              // }
                                columnArr.push(
                                  {title: header, style: {font: {sz: "9", name: 'Calibri', bold: true, color:{rgb: "ffffff"},},
                                  alignment:{vertical: "center",horizontal: "center"},
                                  border:{top:{"style":"thin"},bottom:{"style":"thin"},left:{"style":"thin"},right:{"style":"thin"}},
                                  fill: {patternType: "solid", fgColor: {rgb: "0f243e"}}}},
                                );
                            }                           
                        }
                     }
                    });                 
              }); 

              this.props.csvData.forEach((element, indx)=>{
                let ArrOne = [];                   
                columnArr.forEach((el, indx)=>{
                  if(Array.isArray(element[el]) === false || typeof element[el]!== 'object')
                  {
                    if(Validation.nullAndUndefineCheckFn(element[el.title]))
                    {
                        ArrOne.push(
                            {
                            value: this.checkNumber(element[el.title]), style: {font: {sz: "8", name: 'Calibri'},
                            alignment:{wrapText:true}, numFmt: "0.00",
                            // value: this.checkNumber(element[el.title]), style: {font: {sz: "8", name: 'Calibri'},
                            // alignment:{wrapText:true},
                            border:{top:{"style":"thin"},bottom:{"style":"thin"},left:{"style":"thin"},right:{"style":"thin"}},
                            fill: {patternType: "solid", fgColor: {rgb: "9fa7b1"}}
                            }},
                            );   
                    }else{
                        ArrOne.push(
                            {value: "-", style: {font: {sz: "8", name: 'Calibri'},
                            alignment:{vertical: "center",horizontal: "center"},
                            border:{top:{"style":"thin"},bottom:{"style":"thin"},left:{"style":"thin"},right:{"style":"thin"}},
                            fill: {patternType: "solid", fgColor: {rgb: "9fa7b1"}}
                            }},
                            );  
                    }
                  }
                });
                dataArr.push(ArrOne);
               });

                // this.state.dataSetArr[0]["columns"] = [];
                // this.state.dataSetArr[0]["columns"].push(
                //     {style: {font: {bold: true, name: 'Calibri',sz: "9",
                //      color:{rgb: "ffffff"},}, alignment:{vertical: "center",horizontal: "center"},
                //      fill: {fgColor: {rgb: "0f243e"},},},
                //      title: this.checkNumber(this.props.fileName)+" Report",
                //     },); 
                    
                columnArr.forEach(obj=>{obj.title = this.checkNumber(obj.title);});
                this._state_.dataSetArr[0]["columns"]= columnArr;
                this._state_.dataSetArr[0]["data"]= dataArr;
                this.setState(this._state_ );
            }
        }catch(error){console.log(error);}
                
       }

       checkNumber(data)
       {
            if(/^-?[\d.]+(?:e-?\d+)?$/.test(data) === false)
              return (data.charAt(0).toUpperCase() + data.slice(1));
            else
              return parseFloat(data);
       }

       componentDidUpdate(prevProps, prevState)
       {
         if(prevProps!== this.props)
         {
            this._state_ = this.state;
            this.report_fun();
         }
       }

       componentDidMount()
       {
          this._state_ = this.state;
          this.report_fun();
       }

       render() 
       {
         return (
            <div className="float-right">
                <ExcelFile
                 element={<button className="px-2 py-1 ml-3 pl-2 text-white btn-primary cust_primary btn-sm l-height">Export</button>}
                 filename={this.checkNumber(this.props.fileName)}
                 fileExtension="xlsx">

                    <ExcelSheet dataSet={this.state.dataSetArr}
                     name={this.checkNumber(this.props.fileName)}
                     />
                </ExcelFile>
            </div>           
        );
       }
}

export {ExportReactCSV};