
const ParamCode = {
    
    "GENERAL_G2":
    {
        "G17":"METER_PROGRAM_INCLUDING_VERSION",
        "G1":"METER_NUMBER",
        "G22":"MANUFACTURER_CODE_AND_NAME",
        "G8":"CT_PRIMARY",
        "G7":"PT_PRIMARY",
        "G19":"METER_READING_COUNT",
        "G2":"METER_DATE",
        "G20":"MD_INTEGRATION_PERIOD",
        "G3":"MRI DATE",
        "G4":"DUMP DATE",
        "G9":"PT-PRIMARY PROGRAMMED",
        "G10":"CT-PRIMARY PROGRAMMED",
        "G11":"PT-SECONDARY PROGRAMMED",
        "G12":"CT-SECONDARY PROGRAMMED",
        "G13":"METER CLASS/ACCURACY",
        "G14":"METER RATING",
        "G15":"METER TYPE",
        "G27":"DATA VALIDATION STATUS",
        "G30":"VERSION NO OF INTEROPERATABILITY VERSION",
        "G31":"API VERSION",
        "G32":"CUMULATIVE MD RESET COUNT",
        "G33":"MIOS MEMEBERSHIP ID",
        "G1209":"CUMULATIVE PROGRAMMING COUNT",
        "G1210":"LAST BILLED DATE",
        "G1211":"NO. OF POWER FAILURE",
        "G1212":"DLMS TYPE",
        "G1216":"DLMS FIRMWARE VERSION",
        "G1227":"METER PHASE TYPE",
        "G1228":"TRANSFOMER RATIO CURRENT NUMERATOR",
        "G1230":"TRANSFOMER RATIO VOLTAGE NUMERATOR",
        "G1219":"READS ON PRIOROTY PORT COUNT",
        "G1220":"READS ON NON PRIOROTY PORT COUNT",
        "G1221":"METER YEAR OF MANUFACTURE",
        "G1222":"METER CONSTANT",
        "G1223":"MAX CURRENT",
        "G1224":"MRI ID",
        "G1234":"CATEGORY",
        "G1236":"LOGICAL DEVICE NAME",
        "G1237":"DEMAND INTEGRATION PERIOD"
      },
      "GENERAL_SERVERTIME":
       {
        "G17":"METER_PROGRAM_INCLUDING_VERSION",
        "G1":"METER_NUMBER",
        "G22":"MANUFACTURER_CODE_AND_NAME",
        "G8":"CT_PRIMARY",
        "G7":"PT_PRIMARY",
        "G19":"METER_READING_COUNT",
        "G2":"METER_DATE",
        "G20":"MD_INTEGRATION_PERIOD",
        "G3":"MRI DATE",
        "G4":"DUMP DATE",
        "G9":"PT-PRIMARY PROGRAMMED",
        "G10":"CT-PRIMARY PROGRAMMED",
        "G11":"PT-SECONDARY PROGRAMMED",
        "G12":"CT-SECONDARY PROGRAMMED",
        "G13":"METER CLASS/ACCURACY",
        "G14":"METER RATING",
        "G15":"METER TYPE",
        "G27":"DATA VALIDATION STATUS",
        "G30":"VERSION NO OF INTEROPERATABILITY VERSION",
        "G31":"API VERSION",
        "G32":"CUMULATIVE MD RESET COUNT",
        "G33":"MIOS MEMEBERSHIP ID",
        "G1209":"CUMULATIVE PROGRAMMING COUNT",
        "G1210":"LAST BILLED DATE",
        "G1211":"NO. OF POWER FAILURE",
        "G1212":"DLMS TYPE",
        "G1216":"DLMS FIRMWARE VERSION",
        "G1227":"METER PHASE TYPE",
        "G1228":"TRANSFOMER RATIO CURRENT NUMERATOR",
        "G1230":"TRANSFOMER RATIO VOLTAGE NUMERATOR",
        "G1219":"READS ON PRIOROTY PORT COUNT",
        "G1220":"READS ON NON PRIOROTY PORT COUNT",
        "G1221":"METER YEAR OF MANUFACTURE",
        "G1222":"METER CONSTANT",
        "G1223":"MAX CURRENT",
        "G1224":"MRI ID",
        "G1234":"CATEGORY",
        "G1236":"LOGICAL DEVICE NAME",
        "G1237":"DEMAND INTEGRATION PERIOD"
      },
      "INSTANT_METER_READ_DATE_TIME":{
        "P2-1-1-1-0":"R_INST_LINE_CUR",
        "P2-1-2-1-0":"Y_INST_LINE_CUR",
        "P2-1-3-1-0":"B_INST_LINE_CUR",
        "P3-2-4-1-0":"ACTIVE_POWER_KW",
        "P3-3-4-1-0":"REACTIVE_POWER_KVAR",
        "P3-4-4-1-0":"APPARENT_POWER_KVA",
        "P1-2-1-1-0":"R_INST_VOLTAGE",
        "P1-2-2-1-0":"Y_INST_VOLTAGE",
        "P1-2-3-1-0":"B_INST_VOLTAGE",
        "P4-1-1-0-0":"RPF",
        "P4-2-1-0-0":"YPF",
        "P4-3-1-0-0":"BPF",
        "P4-4-1-0-0":"AVG_PF",
        "P9-1-0-0-0":"FREQUENCY",
        "METER_READ_DATE_TIME":"METER DATE",
        "P5-1-0-0-0":"RYANGLE",
        "P5-2-0-0-0":"YBANGLE",
        "P5-3-0-0-0":"BRANGLE",
        "P2-1-4-1-0":"N_CUR",
        "P8-1-0-0-0":"PH_SEQ",
        "P11-1-0-0-0":"DURATION",
        "P2-2-1-1-0":"R_ACTIVE_CURRENT",
        "P2-2-2-1-0":"Y_ACTIVE_CURRENT",
        "P2-2-3-1-0":"B_ACTIVE_CURRENT",
        "P2-3-1-1-0":"R_REACTIVE_CURRENT",
        "P2-3-2-1-0":"Y_REACTIVE_CURRENT",
        "P2-3-3-1-0":"B_REACTIVE_CURRENT",
        "P2-1-4-4-0":"Neutral Current",
        "P2-1-5-2-0":"Max Current",
        "P7-1-18-2-0":"Cumulative Energy Active KWh",
        "P7-3-18-2-0":"Cumulative Energy Apparent KVAh",
        "P7-2-20-0-0":"Cumulative Energy Reactive Lead KVArh",
        "P7-2-19-0-0":"Cumulative Energy Reactive Lag KVArh",
        "P7-4-18-2-0":"MD Active KW",
        "P7-6-18-2-0":"MD Apparent KVA",
        "P11-2-0-0-0":"Cumulative Power On Duration",
        "P1200-1-0-0-0":"Cumulative Tamper/Event count",
        "P1205-1-1-1-0":"THD Voltage R-phase",
        "P1205-1-2-1-0":"THD Voltage Y-phase",
        "P1205-1-3-1-0":"THD Voltage B-phase",
        "P1206-1-1-1-0":"THD Current R-phase",
        "P1206-1-2-1-0":"THD Current Y-phase",
        "P1206-1-3-1-0":"THD Current B-phase",
        "P1207-1-1-1-0":"THD Active Power R-phase",
        "P1207-1-2-1-0":"THD Active Power Y-phase",
        "P1207-1-3-1-0":"THD Active Power B-phase",
        "P3-1-4-1-0":"ACTIVE_POWER(Non DLMS)",
        "P7-2-0-0-0":"REACTIVE_POWER(Non DLMS)"
      },
      "INSTANT_SERVERTIME":{
        "P2-1-1-1-0":"R_INST_LINE_CUR",
        "P2-1-2-1-0":"Y_INST_LINE_CUR",
        "P2-1-3-1-0":"B_INST_LINE_CUR",
        "P3-2-4-1-0":"ACTIVE_POWER_KW",
        "P3-3-4-1-0":"REACTIVE_POWER_KVAR",
        "P3-4-4-1-0":"APPARENT_POWER_KVA",
        "P1-2-1-1-0":"R_INST_VOLTAGE",
        "P1-2-2-1-0":"Y_INST_VOLTAGE",
        "P1-2-3-1-0":"B_INST_VOLTAGE",
        "P4-1-1-0-0":"RPF",
        "P4-2-1-0-0":"YPF",
        "P4-3-1-0-0":"BPF",
        "P4-4-1-0-0":"AVG_PF",
        "P9-1-0-0-0":"FREQUENCY",
        "METER_READ_DATE_TIME":"METER DATE",
        "P5-1-0-0-0":"RYANGLE",
        "P5-2-0-0-0":"YBANGLE",
        "P5-3-0-0-0":"BRANGLE",
        "P2-1-4-1-0":"N_CUR",
        "P8-1-0-0-0":"PH_SEQ",
        "P11-1-0-0-0":"DURATION",
        "P2-2-1-1-0":"R_ACTIVE_CURRENT",
        "P2-2-2-1-0":"Y_ACTIVE_CURRENT",
        "P2-2-3-1-0":"B_ACTIVE_CURRENT",
        "P2-3-1-1-0":"R_REACTIVE_CURRENT",
        "P2-3-2-1-0":"Y_REACTIVE_CURRENT",
        "P2-3-3-1-0":"B_REACTIVE_CURRENT",
        "P2-1-4-4-0":"Neutral Current",
        "P2-1-5-2-0":"Max Current",
        "P7-1-18-2-0":"Cumulative Energy Active KWh",
        "P7-3-18-2-0":"Cumulative Energy Apparent KVAh",
        "P7-2-20-0-0":"Cumulative Energy Reactive Lead KVArh",
        "P7-2-19-0-0":"Cumulative Energy Reactive Lag KVArh",
        "P7-4-18-2-0":"MD Active KW",
        "P7-6-18-2-0":"MD Apparent KVA",
        "P11-2-0-0-0":"Cumulative Power On Duration",
        "P1200-1-0-0-0":"Cumulative Tamper/Event count",
        "P1205-1-1-1-0":"THD Voltage R-phase",
        "P1205-1-2-1-0":"THD Voltage Y-phase",
        "P1205-1-3-1-0":"THD Voltage B-phase",
        "P1206-1-1-1-0":"THD Current R-phase",
        "P1206-1-2-1-0":"THD Current Y-phase",
        "P1206-1-3-1-0":"THD Current B-phase",
        "P1207-1-1-1-0":"THD Active Power R-phase",
        "P1207-1-2-1-0":"THD Active Power Y-phase",
        "P1207-1-3-1-0":"THD Active Power B-phase",
        "P3-1-4-1-0":"ACTIVE_POWER(Non DLMS)",
        "P7-2-0-0-0":"REACTIVE_POWER(Non DLMS)"
      },
      "LOAD_DATETIME_SLOT":{
        "P1-2-1-4-0":"R_AVG_VOLTAGE",
        "P1-2-1-1-0":"R_INST_VOLTAGE",
        "P1-2-2-4-0":"Y_AVG_VOLTAGE",
        "P1-2-2-1-10":"Y_INST_VOLTAGE",
        "P1-2-3-4-0":"B_AVG_VOLTAGE",
        "P1-2-3-1-0":"B_INST_VOLTAGE",
        "P2-1-1-4-0":"R_AVG_LINE_CUR",
        "P2-1-1-1-0":"R_INST_LINE_CUR",
        "P2-1-2-4-0":"Y_AVG_LINE_CUR",
        "P2-1-2-1-0":"Y_INST_LINE_CUR",
        "P2-1-3-4-0":"B_AVG_LINE_CUR",
        "P2-1-3-1-0":"B_INST_LINE_CUR",
        "P7-1-18-1-0":"KWH_ABS",
        "P7-1-18-2-0":"KWH_TOTAL",
        "P7-3-18-2-0":"KVAH_ABS",
        "P7-2-19-0-0":"KVARH_LG",
        "P7-2-20-0-0":"KVARH_LD",
        "P4-4-4-1-0":"PF",
        "P3-1-1-1-0":"KW_R",
        "P3-1-2-1-0":"KW_Y",
        "P3-1-3-1-0":"KW_B",
        "P3-3-1-1-0":"KVAR_R",
        "P3-3-2-1-0":"KVAR_Y",
        "P3-3-3-1-0":"KVAR_B",
        "P11-1-0-0-0":"POWER_OFF_MIN",
        "DATETIME_SLOT":"DATE TIME",
        "P2-1-4-4-0":"NEUTRAL_CURRENT",
        "P5-1-0-0-0":"VOLTAGE_ANGLE_RY",
        "P5-3-0-0-0":"VOLTAGE_ANGLE_BR",
        "P9-4-0-0-0":"FREQUENCY",
        "P1254-2-7-4-0":"AVERAGE_VOLTAGE",
        "P1253-1-5-4-0":"AVERAGE_CURRENT"
  
      },
      "LOAD_SERVERTIME":{
        "P1-2-1-4-0":"R_AVG_VOLTAGE",
        "P1-2-1-1-0":"R_INST_VOLTAGE",
        "P1-2-2-4-0":"Y_AVG_VOLTAGE",
        "P1-2-2-1-10":"Y_INST_VOLTAGE",
        "P1-2-3-4-0":"B_AVG_VOLTAGE",
        "P1-2-3-1-0":"B_INST_VOLTAGE",
        "P2-1-1-4-0":"R_AVG_LINE_CUR",
        "P2-1-1-1-0":"R_INST_LINE_CUR",
        "P2-1-2-4-0":"Y_AVG_LINE_CUR",
        "P2-1-2-1-0":"Y_INST_LINE_CUR",
        "P2-1-3-4-0":"B_AVG_LINE_CUR",
        "P2-1-3-1-0":"B_INST_LINE_CUR",
        "P7-1-18-1-0":"KWH_ABS",
        "P7-1-18-2-0":"KWH_TOTAL",
        "P7-3-18-2-0":"KVAH_ABS",
        "P7-2-19-0-0":"KVARH_LG",
        "P7-2-20-0-0":"KVARH_LD",
        "P4-4-4-1-0":"PF",
        "P3-1-1-1-0":"KW_R",
        "P3-1-2-1-0":"KW_Y",
        "P3-1-3-1-0":"KW_B",
        "P3-3-1-1-0":"KVAR_R",
        "P3-3-2-1-0":"KVAR_Y",
        "P3-3-3-1-0":"KVAR_B",
        "P11-1-0-0-0":"POWER_OFF_MIN",
        "DATETIME_SLOT":"DATE TIME",
        "P2-1-4-4-0":"NEUTRAL_CURRENT",
        "P5-1-0-0-0":"VOLTAGE_ANGLE_RY",
        "P5-3-0-0-0":"VOLTAGE_ANGLE_BR",
        "P9-4-0-0-0":"FREQUENCY",
        "P1254-2-7-4-0":"AVERAGE_VOLTAGE",
        "P1253-1-5-4-0":"AVERAGE_CURRENT"
  
      },
      "BILLING_B2_DATETIME":{
        "B2_DATETIME":"Energy Date Time",
        "B3_P7-1-18-2-0":"Active energy KWH_Abs_TOTAL",
        "B3_P7-2-19-0-0":"Reactive Energy Lag KVArh",
        "B3_P7-2-20-0-0":"Reactive Energy Lead KVArh",
        "B3_P7-3-18-2-0":"Apparent energy KVAH",
        "B3_P1201-1-18-2-0":"Active Energy KWH L1",
        "B3_P1201-2-18-2-0":"Active Energy KWH L2",
        "B3_P1201-3-18-2-0":"Active Energy KWH L3",
        "B4_P7-1-18-2-0_TOD_1":"TOD 1 Active KWH",
        "B4_P7-2-19-0-0_TOD_1":"TOD 1 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_1":"TOD 1 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_1":"TOD 1 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_2":"TOD 2 Active KWH",
        "B4_P7-2-19-0-0_TOD_2":"TOD 2 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_2":"TOD 2 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_2":"TOD 2 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_3":"TOD 3 Active KWH",
        "B4_P7-2-19-0-0_TOD_3":"TOD 3 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_3":"TOD 3 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_3":"TOD 3 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_4":"TOD 4 Active KWH",
        "B4_P7-2-19-0-0_TOD_4":"TOD 4 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_4":"TOD 4 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_4":"TOD 4 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_5":"TOD 5 Active KWH",
        "B4_P7-2-19-0-0_TOD_5":"TOD 5 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_5":"TOD 5 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_5":"TOD 5 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_6":"TOD 6 Active KWH",
        "B4_P7-2-19-0-0_TOD_6":"TOD 6 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_6":"TOD 6 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_6":"TOD 6 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_7":"TOD 7 Active KWH",
        "B4_P7-2-19-0-0_TOD_7":"TOD 7 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_7":"TOD 7 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_7":"TOD 7 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_8":"TOD 8 Active KWH",
        "B4_P7-2-19-0-0_TOD_8":"TOD 8 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_8":"TOD 8 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_8":"TOD 8 Apparent KVAH",
        "B5_P7-4-18-2-0":"MD Active KW",
        "B5_P7-5-19-0-0":"MD Reactive Lag",
        "B5_P7-5-20-0-0":"MD Reactive Lead",
        "B5_P7-6-18-2-0":"MD Apparent KVA",
        "B6_P7-4-18-2-0_TOD_1":"TOD 1 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_1":"OD 1 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_1":"TOD 1 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_1":"TOD 1 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_2":"TOD 2 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_2":"TOD 2 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_2":"TOD 2 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_2":"TOD 2 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_3":"TOD 3 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_3":"TOD 3 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_3":"TOD 3 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_3":"TOD 3 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_4":"TOD 4 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_4":"TOD 4 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_4":"TOD 4 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_4":"TOD 4 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_5":"TOD 5 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_5":"TOD 5 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_5":"TOD 5 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_5":"TOD 5 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_6":"TOD 6 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_6":"TOD 6 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_6":"TOD 6 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_6":"TOD 6 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_7":"TOD 7 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_7":"TOD 7 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_7":"TOD 7 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_7":"TOD 7 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_8":"TOD 8 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_8":"TOD 8 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_8":"TOD 8 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_8":"TOD 8 MD_Apparent KVA",
        "B9_P4-4-4-0-0":"PF",
        "B11":"Cumulative Power On Time since Meter Manufactured",
        "B12":"Cumulative Power Off Time since Meter Manufactured",
        "B13":"Cumulative Tamper Count",
        "B16":"Name of Tariff file",
        "B3_P7-1-18-1-0":"Active energy KWH_Abs",
        "B3_P7-1-5-3-0":"Active Energy KWH_Import_Defraud",
        "B1201":"L1 Voltage Failure Duration Cumulative",
        "B1202":"L2 Voltage Failure Duration Cumulative",
        "B1203":"L3 Voltage Failure Duration Cumulative",
        "B1204":"L1 Current Failure Duration Cumulative",
        "B1205":"L2 Current Failure Duration Cumulative",
        "B1206":"L3 Current Failure Duration Cumulative",
        "B3_P7-1-18-0-0":"Active energy KWH_Abs",
        "B3_P7-1-5-2-0":"Active energy KWH_Import_TOTAL",
        "B3_P7-2-7-0-0":"Reactive Energy Lag KVArh",
        "B3_P7-2-8-0-0":"Reactive Energy Lead KVArh",
        "B3_P7-3-5-3-0":"Apparent Energy KVAH_Import_Defraud",
        "B5_P7-4-18-0-0":"MD Active KW",
        "B5_P7-6-18-0-0":"MD Apparent KVA"
  
      },
      "BILLING_SERVERTIME":{
        "B2_DATETIME":"Energy Date Time",
        "B3_P7-1-18-2-0":"Active energy KWH_Abs_TOTAL",
        "B3_P7-2-19-0-0":"Reactive Energy Lag KVArh",
        "B3_P7-2-20-0-0":"Reactive Energy Lead KVArh",
        "B3_P7-3-18-2-0":"Apparent energy KVAH",
        "B3_P1201-1-18-2-0":"Active Energy KWH L1",
        "B3_P1201-2-18-2-0":"Active Energy KWH L2",
        "B3_P1201-3-18-2-0":"Active Energy KWH L3",
        "B4_P7-1-18-2-0_TOD_1":"TOD 1 Active KWH",
        "B4_P7-2-19-0-0_TOD_1":"TOD 1 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_1":"TOD 1 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_1":"TOD 1 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_2":"TOD 2 Active KWH",
        "B4_P7-2-19-0-0_TOD_2":"TOD 2 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_2":"TOD 2 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_2":"TOD 2 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_3":"TOD 3 Active KWH",
        "B4_P7-2-19-0-0_TOD_3":"TOD 3 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_3":"TOD 3 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_3":"TOD 3 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_4":"TOD 4 Active KWH",
        "B4_P7-2-19-0-0_TOD_4":"TOD 4 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_4":"TOD 4 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_4":"TOD 4 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_5":"TOD 5 Active KWH",
        "B4_P7-2-19-0-0_TOD_5":"TOD 5 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_5":"TOD 5 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_5":"TOD 5 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_6":"TOD 6 Active KWH",
        "B4_P7-2-19-0-0_TOD_6":"TOD 6 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_6":"TOD 6 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_6":"TOD 6 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_7":"TOD 7 Active KWH",
        "B4_P7-2-19-0-0_TOD_7":"TOD 7 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_7":"TOD 7 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_7":"TOD 7 Apparent KVAH",
        "B4_P7-1-18-2-0_TOD_8":"TOD 8 Active KWH",
        "B4_P7-2-19-0-0_TOD_8":"TOD 8 Reactive Lag KVArh",
        "B4_P7-2-20-0-0_TOD_8":"TOD 8 Reactive Lead KVArh",
        "B4_P7-3-18-2-0_TOD_8":"TOD 8 Apparent KVAH",
        "B5_P7-4-18-2-0":"MD Active KW",
        "B5_P7-5-19-0-0":"MD Reactive Lag",
        "B5_P7-5-20-0-0":"MD Reactive Lead",
        "B5_P7-6-18-2-0":"MD Apparent KVA",
        "B6_P7-4-18-2-0_TOD_1":"TOD 1 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_1":"OD 1 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_1":"TOD 1 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_1":"TOD 1 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_2":"TOD 2 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_2":"TOD 2 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_2":"TOD 2 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_2":"TOD 2 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_3":"TOD 3 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_3":"TOD 3 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_3":"TOD 3 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_3":"TOD 3 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_4":"TOD 4 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_4":"TOD 4 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_4":"TOD 4 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_4":"TOD 4 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_5":"TOD 5 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_5":"TOD 5 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_5":"TOD 5 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_5":"TOD 5 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_6":"TOD 6 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_6":"TOD 6 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_6":"TOD 6 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_6":"TOD 6 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_7":"TOD 7 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_7":"TOD 7 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_7":"TOD 7 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_7":"TOD 7 MD_Apparent KVA",
        "B6_P7-4-18-2-0_TOD_8":"TOD 8 MD_Active KW",
        "B6_P7-5-19-0-0_TOD_8":"TOD 8 MD_Reactive Lag",
        "B6_P7-5-20-0-0_TOD_8":"TOD 8 MD_Reactive Lead",
        "B6_P7-6-18-2-0_TOD_8":"TOD 8 MD_Apparent KVA",
        "B9_P4-4-4-0-0":"PF",
        "B11":"Cumulative Power On Time since Meter Manufactured",
        "B12":"Cumulative Power Off Time since Meter Manufactured",
        "B13":"Cumulative Tamper Count",
        "B16":"Name of Tariff file",
        "B3_P7-1-18-1-0":"Active energy KWH_Abs",
        "B3_P7-1-5-3-0":"Active Energy KWH_Import_Defraud",
        "B1201":"L1 Voltage Failure Duration Cumulative",
        "B1202":"L2 Voltage Failure Duration Cumulative",
        "B1203":"L3 Voltage Failure Duration Cumulative",
        "B1204":"L1 Current Failure Duration Cumulative",
        "B1205":"L2 Current Failure Duration Cumulative",
        "B1206":"L3 Current Failure Duration Cumulative",
        "B3_P7-1-18-0-0":"Active energy KWH_Abs",
        "B3_P7-1-5-2-0":"Active energy KWH_Import_TOTAL",
        "B3_P7-2-7-0-0":"Reactive Energy Lag KVArh",
        "B3_P7-2-8-0-0":"Reactive Energy Lead KVArh",
        "B3_P7-3-5-3-0":"Apparent Energy KVAH_Import_Defraud",
        "B5_P7-4-18-0-0":"MD Active KW",
        "B5_P7-6-18-0-0":"MD Apparent KVA"
  
      },
      "EVENT_SERVERTIME":{
        "D5_EVENT_CODE":"TAMPER_CODE",
        "D5_EVENT_STATUS":"OCC_RES",
        "D5_EVENT_DATETIME":"TAMPER_DATE",
        "P1-2-1-1-0":"VR",
        "P1-2-2-1-0":"VY",
        "P1-2-3-1-0":"VB",
        "P2-2-1-1-0":"IR",
        "P2-2-2-1-0":"IY",
        "P2-2-3-1-0":"IB",
        "P4-1-1-0-0":"PFR",
        "P4-2-1-0-0":"PFY",
        "P4-3-1-0-0":"PFB",
        "P2-1-1-1-0":"LR",
        "P2-1-2-1-0":"LY",
        "P2-1-3-1-0":"LB",
        "P7-1-18-1-0":"WH_ABS"
      },
      "EVENT_D5_EVENT_DATETIME":{
        "D5_EVENT_CODE":"TAMPER_CODE",
        "D5_EVENT_STATUS":"OCC_RES",
        "D5_EVENT_DATETIME":"TAMPER_DATE",
        "P1-2-1-1-0":"VR",
        "P1-2-2-1-0":"VY",
        "P1-2-3-1-0":"VB",
        "P2-2-1-1-0":"IR",
        "P2-2-2-1-0":"IY",
        "P2-2-3-1-0":"IB",
        "P4-1-1-0-0":"PFR",
        "P4-2-1-0-0":"PFY",
        "P4-3-1-0-0":"PFB",
        "P2-1-1-1-0":"LR",
        "P2-1-2-1-0":"LY",
        "P2-1-3-1-0":"LB",
        "P7-1-18-1-0":"WH_ABS"
      },
      "MIDNIGHT_SERVERTIME":{
        "P7-1-18-1-0":"Active Energy KWH",
        "P7-1-18-2-0":"Active Energy KWH",
        "P7-3-18-2-0":"Apparent Energy KVAH",
        "P7-2-8-0-0":"Reactive Energy Lag KVArh",
        "P7-2-19-0-0":"Reactive Energy Lag KVArh",
        "P7-2-7-0-0":"Reactive Energy Lead KVArh",
        "P7-2-20-0-0":"Reactive Energy Lead KVArh",
        "P7-1-5-2-0":"Active Energy KWH_Import_TOTAL",
        "D6_SNAP_DATETIME":"Energy Recorded Date"
      },
      "MIDNIGHT_D6_SNAP_DATETIME":{
        "P7-1-18-1-0":"Active Energy KWH",
        "P7-1-18-2-0":"Active Energy KWH",
        "P7-3-18-2-0":"Apparent Energy KVAH",
        "P7-2-8-0-0":"Reactive Energy Lag KVArh",
        "P7-2-19-0-0":"Reactive Energy Lag KVArh",
        "P7-2-7-0-0":"Reactive Energy Lead KVArh",
        "P7-2-20-0-0":"Reactive Energy Lead KVArh",
        "P7-1-5-2-0":"Active Energy KWH_Import_TOTAL",
        "D6_SNAP_DATETIME":"Energy Recorded Date"
      }
}

export {ParamCode}