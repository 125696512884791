
var meterData = {
    
    meter:[
        "General (D1)",
        "Instant (D2)",
        "Load (D4)",
        "Billing (D3)",
        "Event (D5)",
        "Mid Night (D6)", 
        "Raw",     
    ],

    profile:{
      "General (D1)":[
          {
            name:"SERVER TIME",
            value:"GENERAL_SERVERTIME"
          },
          {
            name:"DATE TIME",
            value:"GENERAL_G2"
          },
      ],
      "Instant (D2)":[
            {
              name:"SERVER TIME",
              value:"INSTANT_SERVERTIME"
            },
            {
                name:"DATE TIME",
                value:"INSTANT_METER_READ_DATE_TIME"
            },
      ],
      "Load (D4)":[
        {
          name:"SERVER TIME",
          value:"LOAD_SERVERTIME"
        },
        {
            name:"DATE TIME",
            value:"LOAD_DATETIME_SLOT"
        },      
      ],
      "Billing (D3)":[
        {
          name:"SERVER TIME",
          value:"BILLING_SERVERTIME"
        },
        {
            name:"DATE TIME",
            value:"BILLING_B2_DATETIME"
        },
     ],
     "Event (D5)":[
      {
        name:"SERVER TIME",
        value:"EVENT_SERVERTIME"
      },
        {
            name:"DATE TIME",
            value:"EVENT_D5_EVENT_DATETIME"
        },
     ],
     "Mid Night (D6)":[
      {
        name:"SERVER TIME",
        value:"MIDNIGHT_SERVERTIME"
      },
        {
            name:"DATE TIME",
            value:"MIDNIGHT_D6_SNAP_DATETIME"
        },
     ],
     "Raw":[
      {
        name:"SERVER TIME",
        value:"RAW_SERVERTIME"
      },
        {
            name:"DATE TIME",
            value:"RAW_DATETIME"
        },
     ],
    },

    meterType:{
      "0":"HT(3p-3w)(0)",
      "1":"HT(3PH-4w)(1)",
      "2":"LT(3ph-3w)(2)",
      "3":"LT(3ph-4w)(3)",
      "4":"WC(3ph-4w)(4)",
      "5":"WC(1ph-2w)(5)"
    },
    
    rmu:[

    ]
}

export {meterData}