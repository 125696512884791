  let exportArr = [];
var Parse = {

    parse_month(data) {

        if (data === 1) {
            return "January";
        } else if (data === 2) {
            return "February";
        } else if (data === 3) {
            return "March";
        } else if (data === 4) {
            return "April";
        } else if (data === 5) {
            return "May";
        } else if (data === 6) {
            return "June";
        } else if (data === 7) {
            return "July";
        } else if (data === 8) {
            return "August";
        } else if (data === 9) {
            return "September";
        } else if (data === 10) {
            return "October";
        } else if (data === 11) {
            return "November";
        } else if (data === 12) {
            return "December";
        }

    },
    monthDoubleDigit(data)
    {
      return ("0" + (data)).slice(-2); 
    },

    parseArryToString(data)
    {
        if(data.length!==0)
        {
            data.forEach(ele => {
                Object.keys(ele).forEach(el=>{ 
                 if((Array.isArray(ele[el]) === false || typeof ele[el]!== 'object') && ele[el]!==null)                     
                    // if(/^-?\d+$/.test(ele[el]))
                    //  ele[el] = ele[el];
                    //  else
                    //  ele[el] = ele[el].toString();
                     if(!isNaN(ele[el]) && ele[el].toString().indexOf('.') != -1)
                     {
                        ele[el] = parseFloat(ele[el]);
                     }else if(/^-?\d+$/.test(ele[el]))
                     {
                        ele[el] = ele[el];
                     }else{
                        ele[el] = ele[el].toString();
                     }
                });                           
            });
            return data;
        }else{return data;}
    },

    setTableWiseColumnExport(arrObjOne, arrObjTwo)
    {
        if(arrObjOne.length!==0)
        {
            let setObj = {}, exportArr = [];
            arrObjOne.forEach(el=>{
                setObj = {};
                arrObjTwo.map(ele=>{if(ele.Header!== "Action" && ele.Header!== "View"){setObj[ele.Header] = el[ele.accessor];}});
                exportArr.push(setObj);
            });
            return exportArr;
        }
    },

    parseTableFilteredData(data)
    {
        if(data.length!==0)
        {
            let setObj = {}, objArr = [];
            data.forEach(ele => {
                setObj = {};
                Object.keys(ele._original).forEach(el=>{                      
                  setObj[el] = ele[el];
                });
                objArr.push(setObj);        
            });
            return objArr;
        }else{return data;}
    },

    parseDateTime(data)
    {
        if(data.length!==0)
        {
            data.forEach(ele => {
               for(let e in ele)
               {
                //  if(new Date(ele[e]) instanceof Date && !isNaN(new Date(ele[e]).valueOf()) && !Number.isInteger(ele[e]))
                //  {
                //     if(ele[e])
                //        ele[e] = ele[e].replace(/T/g, " ");
                //     else 
                //        ele[e] = ele[e]  
                //  }
                    if(ele[e])
                    {
                        if(ele[e].toString().indexOf("T")!== -1 && ele[e].toString().indexOf(":")!== -1 && ele[e].toString().indexOf("-")!== -1)
                        ele[e] = ele[e].replace(/T/g, " ");
                        else 
                        ele[e] = ele[e];
                    }else{
                        ele[e] = ele[e];
                    }
               }      
            });
        }
        return data;
    }
}

export { Parse }