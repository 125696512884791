import React, {Component } from 'react';
import {
   Button, Spinner, Card, CardBody, CardGroup, Col, Container, Form,
  Input, InputGroup, InputGroupAddon, InputGroupText, Row, Label, FormGroup
} from 'reactstrap';
import axios from "axios";
import { URL_PART, STRINGS } from '../../../common/urls.js'
import { ErrorMessages } from '../../../common/Messages.js';
import { Validation } from '../../../common/Validation.js';
import bg_img from '../../../assets/img/login/img_back_login.jpg';
import probus_logo from '../../../assets/img/login/probus_logo.png';
import '../../../views/Pages/TpwodlLogin/TpwodlLogin.css';
import { AppSwitch } from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import '../../../common/Custom.css';


export default class TpwodlLogin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      change_password: "",
      user_type: "",
      loginErrors: "",
      user_details: null,
      user_pass_did_not_match: false,
      user_or_pass_empty: false,
      reset_password: false,
      checkLoaderStatus: false,
      type: "true"
    };


    this._state_ = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSuccesfulAuth = this.handleSuccesfulAuth.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.checkLoginAgain = this.checkLoginAgain.bind(this);
    this.getUserProperties = this.getUserProperties.bind(this);
    this.resetPasswordEnable = this.resetPasswordEnable.bind(this);
    this.getAppswitch = this.getAppswitch.bind(this);
    if (this.props.loggedinstatus === 'LOGGED_IN') {
      var token_str = window.localStorage.getItem('acc_token');
      if (token_str && token_str !== "")
        this.props.history.push("/");
    }
  }

  componentDidMount() {
    this._state_ = this.state;
  }

  componentDidUpdate(prevProps, prevState)
  {
     if(prevState!== this.state)
        this._state_ = this.state;
  }

  getAppswitch() {
    if (this.state.reset_password)
      return (<AppSwitch className={'mx-3 mr-3'} color={'secondary'} size={'sm'} onChange={() => this.resetPasswordEnable()} checked />);
    else
      return (<AppSwitch className={'mx-3 mr-3'} color={'secondary'} size={'sm'} onChange={() => this.resetPasswordEnable()} />);
  }

  handleUserChange(event) 
  {
    this.setState({ user_type: event.currentTarget.defaultValue });
  }

  keyPressed(event) 
  {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  }

  handleSuccesfulAuth(data)
  {
    window.localStorage.setItem('u_name', this.state.username);
    window.localStorage.setItem('acc_token', data.user);
    window.localStorage.setItem('u_login_type', this.state.user_type);
    this.props.handleLogin(data, this.state.user_details);
    window.location.reload();
    this.props.history.push("/");
  }

  resetPasswordEnable(e)
  {
    this.setState({ reset_password: !this.state.reset_password });
  }

  handleChange(event) 
  {
    this.setState({[event.target.name]: event.target.value});
  }

  handleReset(event) 
  {
    const { username, password, change_password } = this.state;
    if (username === "" || password === "" || change_password === "") {
      toast.error("User Name, password new password, type can't be empty!");
    } else 
    {
      this.setState({
        checkLoaderStatus: true
      });
      axios
        .put(
          URL_PART.LOGIN_PASSWORD_RESET,
          {
            userId: username,
            password: password,
            newPassword: change_password
          }

        )
        .then(response => {
          this.setState({
            checkLoaderStatus: false
          });
          if (response.status === 200) {
            toast.success("Successfully Reset Password.");
          }
        })
        .catch(error => {
          this.setState({
            checkLoaderStatus: false
          });
          toast.error(ErrorMessages.composeCommonErrorMsg(error.response.status, error));
        });
    }

  }


  handleSubmit(event) 
  {
    const { username, password, user_type, type } = this.state;

    if (username.trim() === "" || password === "" || user_type === "") {
      toast.error("User Name, password, type can't be empty !");
    } else {
      this.setState({
        checkLoaderStatus: true
      });

      axios
        .post(
          URL_PART.LOGIN_URL + user_type + '&dbOverride=' + type,
          {

            userId: username,
            password: password,
            newPassword: "string"

          }

        )
        .then(response => {
          this.setState({
            checkLoaderStatus: false
          });
          if (response.status === 200) {
            toast.success("Successfully Login");
            this.getUserProperties(response.data.token);
          }
        })
        .catch(error => {
          this.setState({
            checkLoaderStatus: false
          });
          if (error.response.status === 401) {
            if (error.response.data === STRINGS.LOGIN_FAILED_UP_MISMATCH) {
              toast.error("User Name, Password Mismatched !");
            } else {
              toast.error(error.response.data);
            }
          } else {
            toast.error(ErrorMessages.composeCommonErrorMsg(error.response.status, error));
          }
        });
    }

  }

  getUserProperties(token) 
  {
    var getOrg = URL_PART.USER_GET_MY_PROPS;
    var auth = "Bearer " + token;
    axios
      ({
        url: getOrg,
        method: 'get',
        headers: {
          "Accept": "*/*",
          "Authorization": auth
        }
      })
      .then(response => {
        if (response.status === 200) {
          this._state_.user_details = response.data;
          this.setState(this._state_ );
          this.handleSuccesfulAuth({ loggedinstatus: "LOGGED_IN", user: token });
        }
      })
      .catch(error => {
        toast.error(ErrorMessages.composeCommonErrorMsg(error.response.status, error));
      });
  }

  checkLoginAgain() 
  {
    if (this.props.loggedinstatus === 'LOGGED_IN')
      this.props.history.push("/");
  }

  render() 
  {
    const { checkLoaderStatus } = this.state;

      return (
        <div className="bgColor">
          <div className="app">
            <div className="flex-row align-items-center">
                <div lg={12}>
                  <div className="image-container">
                     <img src={bg_img} className="w-100 h-100" alt="sign In Image"/>
                     <div className="card-over-right">
                     <div>
                     <Card className="card-shadow login-card col-lg-12 mb-0">
                     <CardBody className="p-0">
                      <Row>
                        <Col lg="12" className="px-0">
                        <div className="logo-container">
                          <img src={bg_img} className="w-100" alt="sign In Image" />
                          <div className="logo-over-left">
                           <img src={probus_logo} className="logo-w-70" alt="Probus Logo" />                           
                          </div>
                        </div>
                        </Col>
                        <Col lg="12" className="px-0 p-4">
                          {
                            (this.state.reset_password === false)  ?
                            <Form>
                            <h5 className="pb-2 text-center">Sign in</h5>
                            <div className="mb-2">Sign In <strong>TPWODL HES</strong> account
                             <div className="float-right d-flex">
                              <div className="d-flex">Sign in</div>
                                {this.getAppswitch()}
                                <div className="d-flex">Reset</div> </div> </div>

                            <InputGroup className="mb-2" >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="text" name="username" className="btn-pill" placeholder="Username" autoComplete="off" value={this.state.username} onChange={this.handleChange} required />
                            </InputGroup>
                        
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" name="password" className="btn-pill" placeholder="Password" autoComplete="off" value={this.state.password} onChange={this.handleChange} onKeyPress={this.keyPressed} required />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" className="btn-pill" name="selectUser" id="selectUser" onChange={(e) => this.setState({ type: e.target.value })}>
                                <option value="true">DB USER</option>
                                <option value="false">LDAP USER</option>
                              </Input>
                            </InputGroup>
                            <Row >
                              <Col lg="12">
                                <FormGroup check inline>
                                  <Input className="form-check-input" type="radio" id="inline-radio1" name="inline-radios" value="USER" onChange={this.handleUserChange} />
                                  <Label className="form-check-label" check htmlFor="inline-radio1">User</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input className="form-check-input" type="radio" id="inline-radio2" name="inline-radios" value="ADMIN" onChange={this.handleUserChange} />
                                  <Label className="form-check-label" check htmlFor="inline-radio2">Admin</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input className="form-check-input" type="radio" id="inline-radio3" name="inline-radios" value="SENSE_ADMIN" onChange={this.handleUserChange} />
                                  <Label className="form-check-label" check htmlFor="inline-radio3">Probus Admin</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col xs="12" className="mb-2 text-center">
                                <Button disabled={checkLoaderStatus === true} color="primary" className="px-4 btn-pill w-50 btn-sm" onClick={this.handleSubmit}>
                                  {
                                    (checkLoaderStatus === false) ?
                                      <span>Sign in</span> :
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                  }
                                </Button>
                              </Col>
                            </Row>
                            <ToastContainer />
                          </Form>
                            : 
                            
                            <Form>
                            <h5 className="pb-2 text-center">Reset password</h5>
                            <div className="mb-2">Reset <strong>TPWODL HES</strong> password
                                <div className="float-right d-flex">
                                <div className="d-flex">Sign in</div>
                                {this.getAppswitch()}
                                <div className="d-flex">Reset</div> </div> </div>
                              <InputGroup className="mb-2" >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="text" name="username" placeholder="Username" className="btn-pill" autoComplete="off" value={this.state.username} onChange={this.handleChange} required />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" name="password" className="btn-pill" placeholder="Password" autoComplete="off" value={this.state.password} onChange={this.handleChange} onKeyPress={this.keyPressed} required />
                            </InputGroup>
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="btn-pill">
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" name="change_password" className="btn-pill" placeholder="New Password" autoComplete="off" value={this.state.change_password} onChange={this.handleChange} onKeyPress={this.keyPressed} required />
                            </InputGroup>
                            <FormGroup check inline>
                            </FormGroup>
                            <Row>
                              <Col xs="12" className="mb-2 text-center">
                                <Button disabled={checkLoaderStatus === true} color="primary" className="px-4 btn-pill w-50 btn-sm" onClick={this.handleReset}>
                                  {
                                    (checkLoaderStatus === false) ?
                                      <span>Reset</span> :
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                  }
                                </Button>
                              </Col>

                            </Row>
                            <ToastContainer />
                          </Form>
                          }
                        </Col>
                      </Row>
                      </CardBody>
                     </Card>
                     </div>
                     </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      );
  }
}

