import React, {} from 'react'
import ReactModal from 'react-modal';

export const Model = ({_isOpen_, _close_, _selector_, _show_Funct_}) =>{

    if(_isOpen_ && _close_ && _selector_)
    {
       return(<ReactModal 
        isOpen={_isOpen_}
        contentLabel="Example2"
        onRequestClose={_close_}
        className="Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        
        parentSelector={_selector_}
        style={{
        overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
            position: 'absolute',
            top: '15%',
            left: '30%',
            width: '40%',
            border: '1px solid #ccc',
            background: '#fff',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none'
        }
        }}
     >
     {(_show_Funct_) ? _show_Funct_ : ''}
     </ReactModal>)
    }else{return("");}
}