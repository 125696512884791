import React from 'react';
//import {_permission_} from '../src/common/Pemission.js';

const Dashboard = React.lazy(() => import('../views/Dashboards/TpwodlDashboard'));
const ProbusDashboard = React.lazy(() => import('../views/Dashboards/Dashboard'));
const Org = React.lazy(() => import('../views/Orgs/Org/Org'));
const Asset = React.lazy(() => import('../views/Assets/Asset/Asset'));
const UserProfile = React.lazy(() => import('../views/UserAdministration/UserProfile/UserProfile'));
const OrgGraph = React.lazy(() => import('../views/Orgs/OrgGraph/OrgGraph'));
const AdminLand = React.lazy(() => import('../views/AdminLand/AdminLand'));
const Alerts = React.lazy(() => import('../views/Alerts/ALERT/Alerts'));
const Criteria = React.lazy(() => import('../views/Alerts/ALERT/Criteria'));
const AlertHistory = React.lazy(() => import('../views/Alerts/ALERT/AlertHistory'));
const Notifications = React.lazy(() => import('../views/Alerts/ALERT/Notifications'));
const UploadFile = React.lazy(() => import('../views/UploadFile/UploadFile'));
const UploadAssetFile = React.lazy(() => import('../views/Assets/UploadAssetFile/UploadAssetFile'));
const Hub = React.lazy(() => import('../views/Sensors/Hub/Hub'));
// const CommandType = React.lazy(() => import('../views/Commands/CommandType/CommandType'));
const AuditTrail = React.lazy(() => import('../views/AuditTrail/AuditTrail'));
const SensorType = React.lazy(() => import('../views/Sensors/SensorType/SensorType'));
const Sensor = React.lazy(() => import('../views/Sensors/Sensor/Sensor'));
const OrgAssets = React.lazy(() => import('../views/Orgs/OrgAssets/OrgAssets'));
const OBISCODE = React.lazy(() => import('../views/Mappings/OBISCode/OBISCode'));
// const Command = React.lazy(() => import('../views/Commands/Command/Command'));
// const CommandSchedule = React.lazy(() => import('../views/Commands/CommandSchedule/CommandSchedule'));
const Configration = React.lazy(() => import('../views/Configration/Configration'));
// const RawData = React.lazy(() => import('../views/SensorsData/RawData'));
const RawData = React.lazy(() => import('../views/SensorsData/TpwodlRawData'));
// const LatestData = React.lazy(() => import('../views/SensorsData/LatestData'));
const LatestData = React.lazy(() => import('../views/SensorsData/TpwodlLatestData'));
const AssetHealth = React.lazy(() => import('../views/SensorsData/AssetHealth'));
const MeterPassword = React.lazy(() => import('../views/Assets/MeterPassword/MeterPassword'));
const RFGateways = React.lazy(() => import('../views/RF/RFGateways/RFGateways'));
const RFNodes = React.lazy(() => import('../views/RF/RFNodes/RFNodes'));
const RFCommand = React.lazy(() => import('../views/RF/RFCommand/RFCommand'));
// const RFResults = React.lazy(() => import('../views/RF/RFResults/RFResults'));
const AMRMultiplier = React.lazy(() => import('../views/Mappings/AMRMultiplier/AMRMultiplier'));

const EnergyConsumed = React.lazy(() => import('../views/Reports/TpwodlDailyEnergyReport'));
const PeakDemand = React.lazy(() => import('../views/Reports/TpwodlReport'));
const Consolidated = React.lazy(() => import('../views/Reports/TpwodlConsolidatedReport'));
const EventOutage = React.lazy(() => import('../views/Reports/TpwodlEventOutageReport'));
const Billing = React.lazy(() => import('../views/Reports/TpwodlBillingReport'));
const MonthlyEnergy = React.lazy(() => import('../views/Reports/TpwodlMonthlyEnergyReport'));


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/probusDashboard', name: 'Dashboard', component: ProbusDashboard },
  { path: '/Org', exact: true,  name: 'Organisation', component: Org },
  { path: '/Asset', exact: true,  name: 'Asset', component: Asset },
  { path: '/UserProfile', exact: true,  name: 'User Profile', component: UserProfile },
  { path: '/OrgGraph', exact: true,  name: 'Org Graph', component: OrgGraph },
  { path: '/AdminLand', exact: true,  name: 'Admin Land', component: AdminLand },
  { path: '/ALERT', exact: true, name: 'ALERT', component: Alerts},
  { path: '/Configration', exact: true,  name: 'Configration', component: Configration },
  // { path: '/CommandManagement', exact: true, name: 'Command Management', component: CommandType},
  { path: '/SensorsData', exact: true, name: 'Sensors Data', component: LatestData},
  { path: '/Reports', exact: true, name: 'Reports', component: EnergyConsumed},
  { path: '/ALERT/Alerts', exact: true, name: 'Alerts', component: Alerts },
  { path: '/ALERT/Criteria', exact: true, name: 'Criteria', component: Criteria },
  { path: '/ALERT/History', exact: true, name: 'AlertHistory', component: AlertHistory },
  { path: '/ALERT/Notifications', exact: true, name: 'Notifications', component: Notifications },
  { path: '/UploadFile', exact: true,  name: 'UploadFile', component: UploadFile },
  { path: '/UploadAssetFile', exact: true,  name: 'UploadAssetFile', component: UploadAssetFile },
  { path: '/Hub', exact: true,  name: 'Hub', component: Hub },
  // { path: '/CommandType', exact: true,  name: 'CommandType', component: CommandType },
  { path: '/AuditTrail', exact: true,  name: 'AuditTrail', component: AuditTrail },
  { path: '/SensorType', exact: true,  name: 'SensorType', component: SensorType },
  { path: '/Sensor', exact: true,  name: 'Sensor', component: Sensor },
  { path: '/OrgAssets', exact: true,  name: 'OrgAssets', component: OrgAssets },
  { path: '/OBISCode', exact: true,  name: 'OBISCode', component: OBISCODE },
  // { path: '/Command', exact: true,  name: 'Command', component: Command },
  // { path: '/CommandSchedule', exact: true,  name: 'CommandSchedule', component: CommandSchedule },
  { path: '/SensorsData/RawData', exact: true,  name: 'RawData', component: RawData },
  { path: '/SensorsData/LatestData', exact: true,  name: 'LatestData', component: LatestData },
  { path: '/SensorsData/AssetHealth', exact: true,  name: 'AssetHealth', component: AssetHealth },
  { path: '/MeterPassword', exact: true,  name: 'MeterPassword', component: MeterPassword },
  { path: '/rf', exact: true, name: 'rf', component: RFGateways},
  { path: '/rf/RFGateways', exact: true,  name: 'RFGateways', component: RFGateways },
  { path: '/rf/RFNodes', exact: true,  name: 'RFNodes', component: RFNodes },
  { path: '/rf/RFCommand', exact: true,  name: 'RFCommand', component: RFCommand },
  // { path: '/rf/RFResults', exact: true,  name: 'RFResults', component: RFResults },
  { path: '/amrMultiplier', exact: true,  name: 'AMRMultiplier', component: AMRMultiplier },
  { path: '/Reports/energyConsumed', exact: true,  name: 'EnergyConsumed', component: EnergyConsumed },
  { path: '/Reports/PeakDemand', exact: true,  name: 'PeakDemand', component: PeakDemand },
  { path: '/Reports/consolidated', exact: true,  name: 'Consolidated', component: Consolidated },
  { path: '/Reports/eventOutage', exact: true,  name: 'EventOutage', component: EventOutage },
  { path: '/Reports/Billing', exact: true,  name: 'Billing', component: Billing },
  { path: '/Reports/monthlyEnergy', exact: true,  name: 'MonthlyEnergy', component: MonthlyEnergy },
];

export default routes;
