 var Validation = {

      emailValidation(email)
      {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(mailformat.test(email) === true)
        {
          return true;
        }else{
            return false;
        }
      },

      checkNumeraic(data)
      {
          const val = /^[0-9\b]+$/;
          return val.test(data);
      },
      specialcharecter(data)
      {
                var iChars = "!`@#$%^&*()+=-[]\\\';,/{}|\":<>?~";   

                for (var i = 0; i < data.length; i++)
                {      
                    if (iChars.indexOf(data.charAt(i)) !== -1)
                    {    
                      return false; 
                    } 
                }
       },

       nullAndUndefineCheckFn(data)
       {
            if(data!== null && data!==undefined){return true}else{return false;}
       },

       _max_date_fn_()
       {
          return new Date(new Date().getFullYear()+'-'+ 
          (("0" + (new Date().getMonth() + 1)).slice(-2))+'-'+ (("0" + (new Date().getDate())).slice(-2)
          +' '+(("0" + (new Date().getHours())).slice(-2))+ ':'+(("0" + (new Date().getMinutes())).slice(-2))+':'+
          (("0" + (new Date().getSeconds())).slice(-2))));        
       },

       isEmpty(value) {
        return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
      }
 }
 

 export  {Validation};