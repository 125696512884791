//  probus sidebar nav and routes config
      // import probus_nav from '../Navs/_nav.js';
      // import probus_routes from '../Routes/routes';
      // import probus_login from '../views/Pages/Login';

 // tpwodl sidebar nav and routes config
   import tpwodl_nav from '../Navs/tpwodl_nav.js';
   import tpwodl_routes from '../Routes/tpwodl_routes.js';
   import tpwodl_login from '../views/Pages/TpwodlLogin';

var _utility_ = {
      // name:"PROBUS",
      // sBar: probus_nav,
      // route: probus_routes,
      // login: probus_login,
      // amrCommand: true,

      name:"TPWODL",
      sBar: tpwodl_nav,
      route: tpwodl_routes,
      login: tpwodl_login,
      amrCommand: false,
}

export {_utility_}