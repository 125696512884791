
export const _SERVICES_ = {

       _RETRO_: [
           {label:"P_READ_INSTANT", value:"P_READ_INSTANT"},
           {label:"P_READ_LOAD", value:"P_READ_LOAD"},
           {label:"P_READ_BILLING", value:"P_READ_BILLING"},
           {label:"P_READ_EVENT", value:"P_READ_EVENT"},
           {label:"P_READ_MIDNIGHT", value:"P_READ_MIDNIGHT"},
        //    {label:"P_READ_OBIS_REGISTER", value:"P_READ_OBIS_REGISTER"},
        //    {label:"P_READ_OBIS_DATA", value:"P_READ_OBIS_DATA"},
        //    {label:"P_READ_ALL", value:"P_READ_ALL"},
           {label:"LIST_COMMANDS", value:"LIST_COMMANDS"},
           {label:"CANCEL_COMMANDS", value:"CANCEL_COMMANDS"},
           {label:"NODE_RESTART", value:"NODE_RESTART"},
       ],

       _SMART_: [
            {label:"METER_TIME_SYNC", value:"METER_TIME_SYNC"},
            // {label:"SET_METER_PASSWORD", value:"SET_METER_PASSWORD"},
            {label:"SET_LOAD_CURTAIL_LIMIT", value:"SET_LOAD_CURTAIL_LIMIT"},
            {label:"GET_LOAD_CURTAIL_LIMIT", value:"GET_LOAD_CURTAIL_LIMIT"},
            {label:"GET_PROFILE_LOG_INTERVAL", value:"GET_PROFILE_LOG_INTERVAL"},
            {label:"SET_PROFILE_LOG_INTERVAL", value:"SET_PROFILE_LOG_INTERVAL"},
            {label:"GET_INTEGRATION_PERIOD_TIME", value:"GET_INTEGRATION_PERIOD_TIME"},
            {label:"SET_INTEGRATION_PERIOD_TIME", value:"SET_INTEGRATION_PERIOD_TIME"},
            {label:"GET_BILLING_DATE", value:"GET_BILLING_DATE"},
            {label:"SET_BILLING_DATE", value:"SET_BILLING_DATE"},
            {label:"GET_METERING_MODE", value:"GET_METERING_MODE"},
            {label:"SET_METERING_MODE", value:"SET_METERING_MODE"},
            {label:"GET_LOCKOUT_PERIOD", value:"GET_LOCKOUT_PERIOD"},
            {label:"SET_LOCKOUT_PERIOD", value:"SET_LOCKOUT_PERIOD"},
            {label:"CONNECT_DISCONNECT", value:"CONNECT_DISCONNECT"},
            {label:"GET_CONNECT_STATE", value:"GET_CONNECT_STATE"},
            {label:"SET_PREPAID_MODE", value:"SET_PREPAID_MODE"},
            {label:"SET_PREPAID_DETAILS", value:"SET_PREPAID_DETAILS"},
            {label:"GET_PREPAID_DETAILS", value:"GET_PREPAID_DETAILS"},
            // {label:"CHANGE_METER_PASSWORD", value:"CHANGE_METER_PASSWORD"},
            // {label:"CHANGE_METER_KEY", value:"CHANGE_METER_KEY"},
            // {label:"SEND_METER_OTAP_GW", value:"SEND_METER_OTAP_GW"},
            {label:"ACTIVATE_METER_OTAP", value:"ACTIVATE_METER_OTAP"},
            {label:"MD_RESET", value:"MD_RESET"},
            {label:"PING_COMMAND", value:"PING_COMMAND"},
            {label:"SET_TARIFF_TODS", value:"SET_TARIFF_TODS"},
            {label:"GET_TARIFF_TODS", value:"GET_TARIFF_TODS"},
            {label:"GET_METER_DETAILS", value:"GET_METER_DETAILS"},
            {label:"PROPOGATE_SINGLE", value:"PROPOGATE_SINGLE"},
            {label:"PROPOGATE_THREE_PHASE", value:"PROPOGATE_THREE_PHASE"},
       ],

       _HUB_: [{label:"HUB_RESTART", value:"HUB_RESTART"},]


}