
var _config_ = {

    product_type: [
        {"name": "AMR", "show": true,"type":"", "default": true},
        {"name": "AMMR", "show": true, "type":"", "default": false},
        {"name": "RF", "show": true, "type":"sensor", "default": false},
        {"name": "OTHERS", "show": true, "type":"sensor", "default": false}
    ],
}

export {_config_}