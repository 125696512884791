import {_permission_} from '../../src/common/Pemission.js';
 let itemsArr = [],  permissionArr = [
  {name: 'DASHBOARD',url: '/dashboard', icon: 'icon-screen-desktop'},
  {name: 'ADMIN LAND',url: '/AdminLand', icon: 'icon-map'},
  {
    name: 'SENSORS DATA',
    url: '/SensorsData',
    icon: 'icon-feed',
    children: [
      {
        name: 'Sensors List',
        url: '/SensorsData/LatestData',
        icon: 'con-feed',
      },
      {
        name: 'Tabular Data',
        url: '/SensorsData/RawData',
        icon: 'con-feed',
      },
      {
        name: 'Asset Health',
        url: '/SensorsData/AssetHealth',
        icon: 'con-feed',
      },
    ]
  },
  {
    name: 'CONFIGURATION',
    url: '/Configration',
    icon: 'icon-puzzle',
  },
  {
    name: 'ALERT',
    url: '/ALERT',
    icon: 'icon-bell',
    children: _permission_.alert_child_menu().filter(x=>x.name!=="Notifications")
  },
  {name: 'OBIS CODE',url: '/OBISCode', icon: 'icon-map'},
  {name: 'AMR MULTIPLIER', url: '/amrMultiplier', icon: 'icon-map'},
  {
    name: 'RF',
    url: '/rf',
    icon: 'icon-feed',
    children: [
      {
        name: 'Gateway',
        url: '/rf/RFGateways',
        icon: 'con-feed',
      },
      {
        name: 'Node',
        url: '/rf/RFNodes',
        icon: 'con-feed',
      },
      {
        name: 'Command',
        url: '/rf/RFCommand',
        icon: 'con-feed',
      },
      // {
      //   name: 'Result',
      //   url: '/rf/RFResults',
      //   icon: 'con-feed',
      // },
    ]
  },
  {
    name: 'REPORTS',
    url: '/Reports',
    icon: 'icon-book-open',
    children: [
      {
        name: 'ENERGY CONSUMED',
        url: '/Reports/energyConsumed',
        icon: '',
        children: [
          {
            name: 'Daily/Weekly',
            url: '/Reports/energyConsumed',
          },
          {
            name: 'Monthly',
            url: '/Reports/monthlyEnergy',
          },
        ]
      },

      {
        name: 'PEAK DEMAND',
        url: '/Reports/consolidated',
        icon: '',
        children: [
          {
            name: 'Consolidated',
            url: '/Reports/consolidated',
          },
          {
            name: 'Meter Wise',
            url: '/Reports/PeakDemand',
          },
        ]
      },
      {
        name: 'Event Outage',
        url: '/Reports/eventOutage',
      },
      {
        name: 'Billing',
        url: '/Reports/Billing',
      },
    ]
  },
  ];

if(_permission_.sense_admin()) 
{
  itemsArr = permissionArr;
}else{
  itemsArr = permissionArr.filter(x=>x.name!=="OBIS CODE" && x.name!=="AMR MULTIPLIER");
}
     
     
export default {items: itemsArr};
